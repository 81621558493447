.btn-primary {
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  height: 55px;
  width: 100%;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    font-size: 20px;
  }

  @include large {
    font-size: 24px;
    height: 68px;
  }

  &:hover {
    color: white;
  }

  &.disabled {
    color: white;
  }
  &._green {
    background-color: $green;
    border-color: $green;
  }
}

.btn:focus-visible,
:not(.btn-check) + .btn:active {
  color: white;
}

.btn-link {
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 20px;

  @include desktop {
    font-size: 16px;
  }

  @include large {
    font-size: 18px;
    margin-top: 25px;
  }
}

.btn-blue-light {
  background: linear-gradient(
    159.74deg,
    rgba(240, 245, 250, 0.2) 4.12%,
    rgba(255, 255, 255, 0.2) 118.31%
  );
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 18px;
  height: 55px;
  width: 100%;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    font-size: 20px;
  }

  @include large {
    font-size: 24px;
    height: 68px;
  }

  &:hover {
    color: white;
    border: 1px solid #ffffff;
    background-color: $blue;
  }

  &.disabled {
    color: white;
  }
}

.btn-wrapper {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 50px;

  @include large {
    margin-top: 82px;
  }

  .btn-primary {
    width: 350px;

    @include large {
      width: 467px;
    }
  }

  p {
    padding-top: 16px;
    width: 100%;
    text-align: center;

    @include large {
      padding-top: 25px;
    }
  }
}

.add-btn {
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  width: 190px;
  height: 45px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $tangerine;
  cursor: pointer;

  @include mac {
    width: 220px;
  }

  @include large {
    font-size: 18px;
    width: 286px;
    height: 58px;
  }

  &:hover {
    color: white;
  }

  &.absolute {
    position: absolute;
    right: 0;
    top: 0;

    @include small {
      position: relative;
      margin: 20px auto 0 auto;
    }
  }
}

.btn-secondary {
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  height: 55px;
  width: 100%;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  padding: 0 10px;

  @include mac {
    font-size: 18px;
  }

  @include large {
    font-size: 18px;
    height: 59px;
  }

  img {
    margin-right: 10px;
  }

  &:hover {
    color: white;
  }

  &.disabled {
    color: white;
  }
  &.transparent {
    background-color: transparent;
    color: $blue;
    border: 0;
    margin-top: 20px;
    height: auto;
  }
  &.uploadimage{
    width: calc(100% - 150px);
    margin-left: 0px;
    height: 45px;
    font-size: 14px;
    color: white;
    &:hover{
      color: white;
    }
  }
}

.fitler-wrapper {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  margin-top: 15px;

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: $black;
    margin-bottom: 16px;
    width: 100%;

    @include desktop2 {
      font-size: 21px;
    }

    @include large {
      font-size: 24px;
    }
  }

  .btn-filter-btn {
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    height: 35px;
    width: fit-content;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    border-radius: 6px;
    padding: 0 7px;
    margin-right: 10px;
    margin-bottom: 10px;

    @include mac {
      font-size: 12px;
      padding: 0 8px;
    }

    @include desktop2 {
      font-size: 13px;
      padding: 0 10px;
      height: 40px;
      margin-right: 12px;
    }

    @include large {
      font-size: 14px;
      margin-right: 20px;
      border-radius: 8px;
    }

    &:last-child {
      margin-right: 0;
    }

    .icon-color {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 4px;
      border-width: 1px;
      border-style: solid;

      @include large {
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }

      &.clr-task {
        background: #e4f2fa;
      }

      &.clr-1 {
        background: linear-gradient(120.3deg, #f7a072 43.91%, #ffffff 126.57%);
      }

      &.clr-2 {
        background: #a2edc9;
      }

      &.clr-3 {
        background: #e3a2ed;
      }
      // &.clr-person {
      //   backgroundColor: color.backgroundColor,
      //   borderColor: color.borderColor,
      // }
    }

    &.active,
    &:hover {
      color: white;
      background-color: $blue;
    }
  }
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: white;
}
