.stepper-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 50px;
    @include large {
    margin-top: 100px;

    }

    .profile-stepper {
        display: flex;
        flex-flow: wrap;
        width: 100%;

        .stepper-grid {

            border-radius: 12px;
            width: calc(100% / 3 - 55px);
            margin-right: 80px;
            padding: 20px;
            position: relative;

            @include mac {
                width: calc(100% / 3 - 100px);
                margin-right: 147px;
                padding: 30px;
            }

            @include large {
                border-radius: 16px;
                padding: 40px;
            }

            @include small {
                width: calc(100% / 3 - 10px);
                margin-right: 15px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:after {
                content: "";
                width: 40px;
                height: 12px;
                background: url('/assets/images/process-step.png');
                position: absolute;
                right: -60px;
                top: 48%;
                background-size: 100%;
                background-repeat: no-repeat;

                @include mac {
                    width: 85px;
                    height: 15px;
                    right: -115px;

                }

                @include small {
                    display: none;
                }
            }

            &.step-1 {
                background: rgba(77, 116, 164, 0.05);
            }

            &.step-2 {
                background: rgba(77, 116, 164, 0.1);
            }

            &.step-3 {
                background: rgba(77, 116, 164, 0.15);

                &:after {
                    display: none;
                }
            }

            .number {
                font-weight: 700;
                font-size: 60px;
                letter-spacing: 0.03em;
                color: $blue;

                @include mac {
                    font-size: 100px;
                }

                @include large {
                    font-size: 128px;
                }

              

            }

            .subheading {
                font-weight: 600;
                font-size: 18px;
                letter-spacing: 0.03em;
                color: $black;
                margin-top: 25px;


                @include mac {
                    font-size: 24px;
                }

                @include desktop {
                    font-size: 26px;
                }

                @include large {
                    font-size: 32px;
                    margin-top: 35px;

                }
                @include small {
                font-size: 16px;
                    
                }
            }
        }
    }
}

.top-right-bg {
    background: url('/assets/images/bg-profile.svg');
    position: absolute;
    width: 95px;
    height: 95px;
    background-size: 100%;
    top: 35px;
    right: 35px;


    @include large {
        top: 70px;
        right: 70px;
        width: 131px;
        height: 129px;
    }

}

.bottom-left-bg {
    background: url('/assets/images/bg-profile.svg');
    position: absolute;
    width: 95px;
    height: 95px;
    background-size: 100%;
    bottom: 35px;
    left: 35px;

    @include large {
        bottom: 70px;
        left: 70px;
        width: 131px;
        height: 129px;
    }

}