.create-team-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 50px;
  justify-content: center;

  @include large {
    margin-top: 100px;
  }

  .create-team-grid {
    background: #ffffff;
    box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
    border-radius: 12px;
    padding: 40px 20px;
    margin: 0 16px;

    @include mac {
      padding: 40px 40px;
    }

    @include large {
      padding: 82px 82px;
      border-radius: 16px;
    }

    @include small {
      margin-bottom: 32px;
      padding: 40px 40px;
    }

    h2 {
      font-weight: 700;
      font-size: 21px;
      color: $black;
      padding: 0;

      @include mac {
        font-size: 24px;
      }

      @include large {
        font-size: 33px;
      }
    }
  }

  .upload-photo {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 30px;
    position: relative;

    @include large {
      margin-top: 50px;
    }
    .max-img-size-error {
      font-size: 11px;
      position: absolute;
      bottom: -23px;
      color: red;
      left: 75px;
      @include mac {
        left: 110px;
        bottom: -16px;
      }
      @include desktop {
        bottom: -6px;
        left: 110px;
      }
      @include desktop2 {
        bottom: 0;
        font-size: 12px;
      }
      @include large {
        left: 132px;
      }
      @include small {
        left: 85px;
        bottom: -15px;
      }
    }

    .cover-photo {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: solid 2px $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include mac {
        width: 85px;
        height: 85px;
      }

      @include large {
        width: 106px;
        height: 106px;
      }

      &:after {
        content: "";
        background: url("/assets/images/photoCamera.svg");
        width: 30px;
        height: 30px;
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .coverimg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all ease-in-out 0.3s;

        &:hover {
          .hover-action {
            opacity: 1;
          }
        }

        .hover-action {
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.45);
          z-index: 4;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: all ease-in-out 0.3s;

          img {
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
        }
      }
    }
  }

  form {
    margin-top: 20px;
  }

  .download-files {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    background-color: #f1f4f8;
    border-radius: 12px;
    padding: 13px 18px;
    margin-top: 30px;
    align-items: center;

    @include large {
      margin-top: 50px;
      border-radius: 16px;
      padding: 13px 23px;
    }

    .desc {
      width: 100%;
      font-size: 14px;
      color: $black;
      text-align: center;

      @include mac {
        width: 60%;
        text-align: left;
      }

      @include small {
        width: 60%;
        text-align: left;
      }

      @include large {
        font-size: 15px;
      }
    }

    .download-btn {
      width: 100%;
      margin-top: 10px;

      @include mac {
        width: 40%;
        margin-top: 0;
      }

      @include small {
        width: 40%;
      }

      .btn-download {
        width: 100%;
        border: solid 1px $blue;
        height: 40px;
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: $black;

        @include large {
          font-size: 16px;
          height: 44px;
        }

        .icon-download {
          width: 24px;
          height: 24px;
          margin-right: 5px;

          @include large {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .progress-content-bar {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    margin-top: 15px;
    margin-bottom: 10px;
    align-items: center;
    min-height: 50px;

    @include small {
      min-height: 28px;
    }

    @include mac {
      margin-bottom: 10px;
    }

    @include large {
    }

    ._image {
      width: 35px;
      // top: 8px;
      position: relative;
    }

    .bar-content {
      width: calc(100% - 35px);
    }

    .progress {
      height: 14px;
      border-radius: 20px;
      margin-top: 2px;

      .progress-bar {
        font-size: 12px;
        background-color: #71c49c;
      }
    }
  }

  &.task-wrapper {
    .task-form {
      margin-top: 5rem;

      .redio-wrap {
        padding-bottom: 55px;
        @include mac {
          padding-bottom: 45px;
        }

        @include large {
          // padding-bottom: 110px;
        }
      }
    }

    .btn.btn-primary {
      margin-top: 10px;
    }

    .info-action {
      margin-left: 6px;
      img {
        width: 23px;
      }
    }

    .scope-action {
      img {
        width: 22px;
        padding-left: 7px;
      }
    }

    .check-list {
      margin: 20px 0 0 13px;
      align-items: flex-end;
    }
  }
  &.createteampage {
    .progress-content-bar {
      min-height: 55px;

      @include mac {
        min-height: 95px;
      }
      @include large {
        min-height: 108px;
      }
    }
  }
}
