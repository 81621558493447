.contact-left-panel::-webkit-scrollbar {
  background: rgba(77, 116, 164, 0.1);
  width: 7px;
  border-radius: 12px;
}

.contact-left-panel::-webkit-scrollbar-track {
  background: rgba(77, 116, 164, 0.1);
}

.contact-left-panel::-webkit-scrollbar-thumb {
  background-color: rgba(77, 96, 164, 0.5);
  border-radius: 12px;
  border: 0px solid #f4f4f5;
  opacity: 0.1;
}

.contact-left-panel::-webkit-scrollbar-button {
  display: none;
}
.contact-left-panel {
  background: linear-gradient(159.74deg, #f0f5fa 4.12%, #ffffff 118.31%);
  border-radius: 8px;
  padding: 20px 10px 20px 15px;
  height: 640px;
  overflow-y: auto;

  @include desktop2 {
    padding: 25px 16px 25px 25px;
    height: 640px;
    overflow-y: auto;
  }

  @include large {
    border-radius: 16px;
    padding: 32px 16px 32px 28px;
    height: 640px;
    overflow-y: auto;
  }
  .scrollbar::-webkit-scrollbar {
    background: rgba(77, 116, 164, 0.1);
    width: 7px;
    border-radius: 12px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: rgba(77, 116, 164, 0.1);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(77, 96, 164, 0.5);
    border-radius: 12px;
    border: 0px solid #f4f4f5;
    opacity: 0.1;
  }

  .scrollbar::-webkit-scrollbar-button {
    display: none;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    color: $black;
    margin: 0;
    padding: 0;

    @include desktop2 {
      font-size: 22px;
    }

    @include large {
      font-size: 32px;
    }
  }

  .user-profile-desc {
    width: 100%;
    padding: 0 12px 0 0;
    margin-top: 24px;
  }

  .user-profile-panel {
    background: linear-gradient(120.3deg, #f7a072 43.91%, #ffffff 126.57%);
    border: 1px solid #f7a072;
    border-radius: 8px;
    padding: 15px 10px;
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-bottom: 16px;

    @include desktop2 {
      padding: 20px 15px;
    }

    @include large {
      padding: 19px;
    }
    .desc-text {
      width: 100%;
      background-color: white;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 10px 10px;
      min-height: 80px;
      @include desktop2 {
        padding: 13px 16px;
      }
    }

    &._without-bg {
      background: transparent;
      border: 0;
      padding: 0;
      textarea {
        height: 150px;
        padding: 15px;
      }
      .profile-desc {
        .user-info {
          h4 {
            color: $black;
          }

          p {
            color: $black;
          }
        }
      }
      .status {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-flow: wrap;
        gap:10px;
      }
      .assigned-tag {
        width: fit-content;
        padding: 3px 10px;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 600;
        text-transform: uppercase;
        background: #efbf62;
        border-radius: 6px;
        color: white;
        display: flex;
        align-items: center;
        height: 38px;

        @include desktop2 {
          padding: 3px 10px;
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
          padding: 4px 20px;
          border-radius: 8px;
          height: 45px;
        }

        img {
          width: 100%;
          height: 100%;
          background-size: cover;
          border-radius: 50%;
          background-color: white;
        }
      }

      .done-tag {
        width: fit-content;
        padding: 3px 10px;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 600;
        text-transform: uppercase;
        background: #71c49c;
        border-radius: 6px;
        color: white;
        display: flex;
        align-items: center;
        height: 38px;

        @include desktop2 {
          padding: 3px 10px;
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
          padding: 4px 20px;
          border-radius: 8px;
          height: 45px;
        }

        img {
          width: 100%;
          height: 100%;
          background-size: cover;
          border-radius: 50%;
          background-color: white;
        }
      }
      .next-match {
        width: fit-content;
        padding: 3px 10px;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 600;
        text-transform: uppercase;
        background: $blue;
        border-radius: 6px;
        color: white;
        display: flex;
        align-items: center;
        height: 38px;

        @include desktop2 {
          padding: 3px 20px;
          font-size: 16px;
        }

        @include large {
          font-size: 16px;
          padding: 4px 15px;
          border-radius: 8px;
          height: 45px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          // width: 100%;
          margin-top: 10px;
        }
      }
    }

    .profile-desc {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;

      .user-profile {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        background-color: white;

        @include desktop2 {
          width: 64px;
          height: 64px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .badges {
          width: auto;
          height: auto;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .user-info {
        width: calc(100% - 100px);
        padding-left: 10px;
        padding-right: 6px;

        @include desktop2 {
          width: calc(100% - 125px);
          padding-left: 16px;
        }

        @media screen and (min-width: 992px) and (max-width: 1200px) {
          width: calc(100% - 50px);
        }

        h4 {
          font-weight: 600;
          font-size: 15px;
          color: white;
          width: 100%;

          @include mac {
            font-size: 16px;
          }

          @include desktop2 {
            font-size: 18px;
          }

          @include large {
            font-size: 20px;
          }
        }

        P {
          padding: 0;
          margin: 0;
          font-weight: 400;
          font-size: 13px;
          width: 100%;
          color: white;

          @include desktop2 {
            font-size: 14px;
          }

          @include large {
            font-size: 16px;
          }
        }
      }

      .rank-info {
        width: 50px;
        height: 33px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        font-weight: 500;
        font-size: 13px;

        @include desktop2 {
          width: 60px;
          height: 37px;
        }

        @media screen and (min-width: 992px) and (max-width: 1200px) {
          margin-top: 5px;
        }

        @include large {
          font-size: 14px;
        }

        &._yellow {
          background: #fbecb6;
        }

        &._green {
          background: #a2edc9;
        }

        &._pink {
          background: #e3a2ed;
        }
      }
    }

    .task-capacity {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 10px 8px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      width: 100%;
      margin-top: 16px;

      @include desktop2 {
        padding: 13px 8px;
      }

      .taskheading {
        width: 50%;
        font-weight: 600;
        font-size: 15px;
        color: $black;

        @include desktop2 {
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
        }
      }

      .task-sizes {
        width: 50%;
        display: flex;
        justify-content: flex-end;

        .size-items {
          font-weight: 600;
          font-size: 12px;
          color: $black;
          margin-right: 10px;

          @include desktop2 {
            font-size: 13px;
          }

          @include large {
            font-size: 14px;
          }

          @media screen and (min-width: 992px) and (max-width: 1200px) {
            text-align: center;
          }

          &:last-child {
            margin-right: 0;
          }

          span {
            background-color: $blue;
            border-radius: 6px;
            font-weight: 600;
            font-size: 12px;
            color: white;
            padding: 5px 5px;
            text-transform: uppercase;
            min-width: 22px;
            display: inline-flex;
            justify-content: center;
            margin-right: 3px;

            @include desktop2 {
              min-width: 25px;
              padding: 7px 5px;
              border-radius: 8px;
              font-size: 13px;
            }

            @include large {
              font-size: 14px;
            }
          }
        }
      }
    }

    .matching-info {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 10px 10px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      width: 100%;
      margin-top: 16px;
      justify-content: space-between;

      @include desktop2 {
        padding: 13px 16px;
      }

      &._blue-color {
        border-color: $blue;

        .infoheading {
          color: $blue;
        }
      }

      .infoheading {
        font-weight: 600;
        font-size: 15px;
        color: $black;
        margin-right: 10px;

        @include desktop2 {
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
        }
      }
    }

    .all-info-detail {
      width: 100%;
      padding: 10px 10px 0 10px;

      &.text-color-white {
        color: $white;
      }

      &.text-color-black {
        color: $black;
      }

      @include desktop2 {
        padding: 16px 16px 0 16px;
      }

      ul {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        margin: 0;
        padding: 0;

        li {
          display: inline-flex;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          padding: 4px 0;

          @include mac {
            font-size: 15px;
          }

          @include large {
            font-size: 16px;
          }

          .content {
            width: 80%;
            color: $white;
          }

          .percentage-text {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            color: $white;
          }
        }
      }
    }

    &._with-white-bg {
      background: white;
      border-color: $blue;

      .profile-desc {
        h4 {
          color: $black;
        }

        p {
          color: $black;
        }
      }
    }
  }
}

.contact-content-wrapper {
  .fitler-wrapper {
    padding: 0 20px;
    position: absolute;
    bottom: 10px;
    z-index: 999;
    .filter-btn {
      width: 60%;
      flex-wrap: wrap;
    }
    // &.bottom-130 {
    //   bottom: 130px;
    // }
    // margin-top: 20px;
    @include small {
      bottom: 0px;
    }
    @include mac {
      padding: 0 30px;
    }

    @include desktop2 {
      padding: 0 50px;
    }
  }

  .contact-description {
    width: 100%;
    padding: 0 20px;

    @include mac {
      padding: 0 30px;
    }

    @include desktop2 {
      padding: 0 50px;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      color: $black;
      margin-bottom: 16px;

      @include desktop2 {
        font-size: 21px;
      }

      @include large {
        font-size: 24px;
      }
    }
  }

  .dimension-panel {
    padding-top: 20px;
    padding-bottom: 0;

    @include large {
      padding-top: 0px;
    }
  }
}
