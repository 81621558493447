@import "config/variables";
// Override default variables before the import
$theme-colors: (
  "primary": $tangerine,
  "secondary": $blue,
  "success": $green,
  "danger": $error,
  "warning": $yellow,
  "info": $suggestion,
  "light": $gray,
  "dark": $black,
);
@import 'react-datepicker/dist/react-datepicker.css';

//@import  "rsuite/dist/rsuite.min.css";
// Import Bootstrap and its default variables
@import "rsuite/dist/rsuite.css";

@import "~bootstrap/scss/bootstrap.scss";

@import "config/media-queries", "base/__base", "components/__components",
  "layout/__layout";


.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  &.member-profile {
    height: 80%;
    width: 80%;
    margin: 0 10%;
  }
  &.large {
    height: 80%;
    width: 90%;
    margin-left: 10%;
  }
  &.assigned {
    height: 90%;
    // width: 100%;
    // margin: 0 15%;
    display: flex;
    justify-content: center;
  }
  &.suggestion {
    height: 80%;
    display: flex;
    justify-content: center;
  }
  @include small {
    &.suggestion,
    &.assigned {
      height: 600px;
      width: 100%;
    }
    &.member-profile {
      height: 392px;
      margin: 0 5%;
    }

    height: 35vh;
    width: 64vw;
    display: flex;
    justify-content: center;
  }
}
.pointer-none {
  pointer-events: none;
}

.progress-percentage {
  margin-left: 20px;
}
.pe-cursor {
  cursor: pointer;
}
