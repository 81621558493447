.task-myprofile-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 85px;

  @include large {
    margin-top: 100px;
  }

  h2 {
    font-weight: 600;
    font-size: 22px;
    color: $black;
    margin-bottom: 25px;

    @include large {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  .btn-seeAll {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: $tangerine;
    border: 0;
    padding: 0;

    @include desktop2 {
      font-size: 16px;
    }

    @include large {
      font-size: 18px;
    }

    &:hover {
      color: $tangerine;
    }
  }

  .profile-left-content {
    margin-bottom: 50px;

    @include mac {
      padding-right: 20px;
      margin-bottom: 0;
    }

    .profile-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }

      ._profile-img {
        width: 72px;
        height: 72px;
        position: relative;
        background-color: white;
        border-radius: 50%;

        @include large {
          width: 82px;
          height: 82px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .badges {
          position: absolute;
          bottom: 0;
          right: 0;
          width: auto;
          height: auto;
        }
      }

      .info-user {
        width: calc(100% - 112px);
        padding-left: 20px;

        @include large {
          width: calc(100% - 126px);
          padding-left: 30px;
        }

        .name {
          font-weight: 600;
          font-size: 18px;
          color: $black;
          width: 100%;
          margin: 0px 0;

          @include large {
            font-size: 24px;
          }
        }

        .designation {
          font-weight: 400;
          font-size: 14px;
          color: $black;
          width: 100%;
          padding-bottom: 3px;
          padding-top: 6px;

          @include large {
            font-size: 16px;
          }
        }

        .email {
          font-weight: 500;
          font-size: 14px;
          color: $black;

          @include large {
            font-size: 16px;
          }
        }
      }

      .btn-edit {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: $tangerine;
        border: 0;
        padding: 0;

        @include desktop2 {
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
        }

        &:hover {
          color: $tangerine;
        }
      }
    }

    .your-plan-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 30px;

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }

      .left-content {
        width: 50%;
        display: flex;
        flex-flow: wrap;

        h2 {
          margin: 0;
        }

        .base-info {
          width: 100%;
          padding-top: 16px;

          .base {
            width: 100%;
            font-weight: 400;
            font-size: 24px;
            color: $black;
          }

          .text {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            color: $black;
          }
        }
      }

      .right-content {
        width: 50%;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;

        .subscription {
          width: 100%;
          display: flex;
          color: $black;
          font-size: 18px;
          padding-top: 48px;
          justify-content: flex-end;

          .ammount {
            font-weight: 400;
            font-size: 24px;
            position: relative;
            top: -5px;
          }
        }
      }
    }

    .payment-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 30px;

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }

      ._payment-form {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        margin-bottom: 21px;
        min-height: 62px;
        @include desktop2 {
          margin-bottom: 25px;
        }
        @include large {
          min-height: 50px;
          margin-bottom: 17px;
        }

        .input-field {
          width: calc(100% - 70px);
          padding-right: 20px;
        }

        .btn-editpay {
          width: 25px;
          text-align: center;
          margin-right: 20px;
          padding: 0;
        }

        .btn-delete-pay {
          width: 25px;
          text-align: center;
          padding: 0;
        }
      }
    }
  }

  .profile-right-content {
    padding-left: 0px;

    @include mac {
      padding-left: 20px;
    }

    h2 {
      font-weight: 600;
      font-size: 26px;
      color: $black;
      margin-bottom: 0px;

      @include large {
        font-size: 32px;
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;

      .btn-seeAll {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: $tangerine;
        border: 0;

        @include desktop2 {
          font-size: 16px;
        }

        @include large {
          font-size: 18px;
        }

        &:hover {
          color: $tangerine;
        }
      }
    }

    .active-user-content-wrapper {
      // box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 12px;
      width: 100%;
      background-color: #f6f8fb;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      flex-flow: wrap;
      color: $black;

      @include large {
        padding: 30px;
        border-radius: 16px;
      }

      h3 {
        font-weight: 600;
        font-size: 22px;

        @include large {
          font-size: 24px;
        }
      }

      .active-user-content::-webkit-scrollbar {
        background: rgba(77, 116, 164, 0.1);
        width: 6px;
        border-radius: 12px;
        height: 6px;
      }
      
      .active-user-content::-webkit-scrollbar-track {
        background: rgba(77, 116, 164, 0.1);
      }
      
      .active-user-content::-webkit-scrollbar-thumb {
        background-color: rgba(77, 96, 164, 0.5);
        border-radius: 12px;
        border: 0px solid #f4f4f5;
        opacity: 0.1;
      }
      
      .active-user-content::-webkit-scrollbar-button {
        display: none;
      }

      .active-user-content {
        display: flex;
        // flex-flow: wrap;
        width: calc(100% + 20px);
        margin: 0 -10px;
        // max-height: 245px;
        overflow-x: auto;
        
        .active-list-grid {
          background: white;
          border-radius: 12px;
          min-width: 170px;
          width: calc(100% / 4 - 20px);
          padding: 15px 10px;
          display: flex;
          flex-flow: wrap;
          transition: all ease-in-out 0.3s;
          margin-right: 10px;
          margin-left: 10px;
          justify-content: center;
          margin-bottom: 15px;

          @include desktop2 {
            padding: 15px 10px;
            width: calc(100% / 4 - 20px);
          }

          ._profile-img {
            width: 82px;
            height: 82px;
            position: relative;
            background-color: white;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }

            .badges {
              position: absolute;
              bottom: 0;
              right: 0;
              width: auto;
              height: auto;
            }
          }

          .name {
            font-weight: 600;
            font-size: 15px;
            color: $black;
            width: 100%;
            text-align: center;
            margin: 8px 0;
          }

          .designation {
            font-weight: 400;
            font-size: 12px;
            color: $black;
            width: 100%;
            text-align: center;
            padding-bottom: 6px;
          }

          .free-slot {
            display: flex;
            flex-flow: wrap;
            width: 100%;
            border-top: 1px solid #373e45;
            text-align: center;
            justify-content: center;
            .task-capacity-n {
              justify-content: center;
            }

            .text {
              width: 100%;
              font-weight: 600;
              font-size: 12px;
              color: $black;
              padding-bottom: 12px;
              padding-top: 10px;
            }

            .sizes {
              display: flex;
              align-items: center;
              font-weight: 600;
              font-size: 14px;
              color: $black;
              margin-right: 4px;

              @include desktop {
                margin-right: 9px;
              }

              @include desktop2 {
                margin-right: 8px;
              }

              &:last-child {
                margin-right: 0;
              }

              .tag-info {
                width: 22px;
                height: 25px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: 600;
                font-size: 13px;
                line-height: 21px;
                text-transform: uppercase;
                background-color: $blue;
                margin-right: 3px;

                @include desktop2 {
                  margin-right: 5px;
                  width: 23px;
                  height: 25px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      &.non-active-user-content-wrapper {
        background-color: #f6f8fb;
        margin-top: 20px;
        box-shadow: none;

        .active-user-content {
          // max-height: 205px;
          // overflow: auto;
          .active-list-grid {
            background-color: white;

            ._profile-img {
              background-color: #f6f8fb;
            }

            .name {
              margin-bottom: 2px;
            }

            .btn-secondary {
              font-weight: 600;
              font-size: 12px;
              height: auto;
              padding: 5px 8px;

              @include large {
                padding: 7px 8px;
              }
            }
          }
        }
      }
    }
  }
}

.slot-free-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
  .slotheding {
    width: 75px;
    font-size: 13px;
    font-weight: 600;
    color: $black;
    @include desktop {
      width: 95px;
    }
  }
  .info-size {
    width: calc(100% - 75px);
    display: flex;
    flex-flow: wrap;
    @include desktop {
      width: calc(100% - 95px);
    }
  }
  .sizes {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $black;
    margin-right: 5px;

    @include desktop {
      margin-right: 10px;
    }

    @include desktop2 {
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0;
    }

    .tag-info {
      width: 22px;
      height: 25px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 21px;
      text-transform: uppercase;
      background-color: $blue;
      margin-right: 3px;

      @include desktop2 {
        margin-right: 5px;
        width: 23px;
        height: 25px;
        font-size: 13px;
      }
    }
  }
}

.personal-profile-info {
  background: #f6f8fb;
  border-radius: 12px;
  padding: 20px 20px;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  align-items: flex-start;
  margin-top: 0px;

  @include large {
    border-radius: 16px;
    padding: 25px 30px;
  }

  .left-content {
    width: 70%;
    display: flex;
    flex-flow: wrap;

    h2 {
      margin: 0;
      font-size: 22px;
      @include desktop {
        font-size: 24px;
      }
      @include large {
        font-size: 28px;
      }
    }
  }

  .right-content {
    width: 30%;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
  }
  .personal-info-details {
    width: 100%;
    display: flex;
    flex-flow: wrap;
  .profile-added-content{
    display: flex;
    flex-flow: wrap;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 35px;
    .uploadimage{
     
    }
  }
    ._profile-img {
      width: 150px;
      height: 150px;
      position: relative;
      background-color: white;
      border-radius: 50%;
      margin: 35px 0 0px 0;

      @include large {
        width: 150px;
        height: 150px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }
    .personaltext {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      margin: 13px 0;
      @include desktop2 {
        margin: 15px 0;
      }
      @include large {
        margin: 18px 0;
      }
      .hd-info {
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        color: $black;
        padding-bottom: 5px;
      }
      .white-bx {
        width: 100%;
        height: 45px;
        background-color: white;
        border-radius: 6px;
        p {
          padding: 10px 5px 10px 10px;
          margin: 0;
          font-size: 15px;
          color: #373E45;
        }
      }
    }
  }
}
