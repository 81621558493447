.team-task-list-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 40px;

  @include large {
    margin-top: 60px;
  }
  .common-right-panel{
    .grid-scroll{
      height: 720px !important;
    }
  }

  .team-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 26px;

    h2 {
      font-weight: 700;
      font-size: 22px;
      letter-spacing: 0.03em;

      @include desktop2 {
        font-size: 26px;
      }

      @include large {
        font-size: 32px;
      }
    }

    .btn-seeAll {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      color: $tangerine;
      border: 0;

      @include desktop2 {
        font-size: 16px;
      }

      @include large {
        font-size: 18px;
      }

      &:hover {
        color: $tangerine;
      }
    }
  }

  .search-with-filter {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-bottom: 34px;

    .search-bar {
      display: flex;
      flex-flow: wrap;
      width: 240px;
      height: 37px;

      @include mac {
        width: 258px;
      }

      @include large {
        height: 47px;
      }

      form {
        margin: 0;
        position: relative;
        width: 100%;
      }

      ._search {
        border: 0;
        position: absolute;
        right: 10px;
        top: 8px;
        background: transparent;
        max-width: 16px;

        @include large {
          right: 15px;
          top: 12px;
        }
      }

      input {
        width: 100%;
        padding: 0 42px 0 10px;
        border: 1px solid #ccd2db;
        height: 37px;

        @include large {
          height: 47px;
        }
      }
    }

    .filter-btn {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: fit-content;
      padding: 0 10px;
      text-transform: uppercase;
      border: solid 1px $blue;
      color: $blue;
      font-weight: 700;
      font-size: 14px;
      margin-left: 10px;
      height: 37px;

      @include mac {
        margin-left: 15px;
      }

      @include large {
        height: 47px;
        border-radius: 8px;
        font-size: 18px;
        margin-left: 20px;
      }

      img {
        margin-left: 6px;
        max-width: 16px;
        max-height: 17px;
      }
      ._blue-icon {
      }
      ._white-icon {
        display: none;
      }
    }
    .selected-filter-btn {
      img {
        margin-left: 6px;
        max-width: 16px;
        max-height: 17px;
      }
      ._blue-icon {
        display: none;
      }
      ._white-icon {
        display: inline-block;
      }
    }

    .selected-filter-btn {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: fit-content;
      padding: 0 10px;
      text-transform: uppercase;
      border: solid 1px $blue;
      color: $white;
      background-color: $blue;

      font-weight: 700;
      font-size: 14px;
      margin-left: 10px;
      height: 37px;

      @include mac {
        margin-left: 15px;
      }

      @include large {
        height: 47px;
        border-radius: 8px;
        font-size: 18px;
        margin-left: 20px;
      }

      img {
        margin-left: 6px;
      }
    }

    .btn-content {
      display: flex;
      justify-content: flex-end;
    }

    .btn-suggestions {
      height: 37px;
      background: #40bbbd;
      border: 1px solid #40bbbd;
      border-radius: 6px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      color: white;
      padding: 0 10px;

      @include mac {
        font-size: 13px;
      }

      @include large {
        border-radius: 8px;
        padding: 0 10px;
        height: 47px;
        font-size: 16px;
      }
    }
  }

  .grid-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    
  }

  .grid-scroll {
    height: 650px;
    overflow: auto;
  }

  .task-list-content {
    display: flex;
    flex-flow: wrap;
    width: calc(100% + 10px);
    // max-height: 50vh;
    overflow-y: auto;
    padding-right: 7px;
    position: relative;
    align-items: flex-start;
    margin-left: -5px;
    margin-right: -5px;
    // .bottom-graidient {
    //     background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.08%, rgba(255, 255, 255, 0.927083) 44.27%, #FFFFFF 72.4%);
    //     border: solid 1px red;
    //     position: absolute;
    //     bottom: 0;
    //     height: 50px;
    //     width: 100%;
    //     left: 0;
    // }

    .task-list-grid {
      background: linear-gradient(159.74deg, #f0f5fa 4.12%, #ffffff 118.31%);
      border-radius: 12px;
      margin-bottom: 15px;
      width: calc(100% / 2 - 10px);
      margin-right: 13px;
      padding: 20px 15px;
      display: flex;
      flex-flow: wrap;
      transition: all ease-in-out 0.3s;
      margin-bottom: 10px;
      margin-left: 5px;
      margin-right: 5px;

      @include mac {
      }

      @include desktop {
        margin-bottom: 15px;
        width: calc(100% / 2 - 10px);
        margin-left: 5px;
        margin-right: 5px;
      }

      @include desktop2 {
        margin-bottom: 15px;
      }

      @include large {
        margin-bottom: 20px;
        border-radius: 16px;
        width: calc(100% / 3 - 10px);

      }

      &.not-started-task {
        @include mac {
          margin-bottom: 10px;
          width: calc(100%);
        }

        @include desktop2 {
          margin-bottom: 10px;
          width: calc(100% / 2 - 6px);
          margin-left: 3px;
          margin-right: 3px;
        }
      }

      &:hover {
        background: linear-gradient(120.3deg, #f7a072 43.91%, #ffffff 126.57%);

        .member-profile {
          .name {
            color: $white;
          }
        }

        .email {
          a {
            color: $white;
          }

          color: $white;
        }

        path {
          fill: white !important;
        }
        .delete {
          ._delete-icon {
            display: none !important;
          }
          ._delete-icon-hover {
            display: inline-block !important;
          }
        }
      }

      .member-profile {
        width: 50%;

        ._image {
          width: 64px;
          height: 64px;
          position: relative;
          border-radius: 50%;
          background-color: $white;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            border-radius: 50%;
          }

          &:after {
            content: "";
            background: url("/assets/images/Badge.svg");
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 20px;
            height: 20px;
          }
        }

        .name {
          font-weight: 600;
          font-size: 14px;
          color: $black;
          margin-top: 8px;

          @include desktop {
            font-size: 14px;
          }

          @include large {
            font-size: 16px;
          }
        }
      }

      .action-btn {
        width: 40%;
        display: flex;
        justify-content: flex-end;

        .delete {
          cursor: pointer;
          position: relative;
          right: -5px;
          height: 25px;
          ._delete-icon-hover {
            display: none;
          }
          ._delete-icon {
            display: inline-block;
          }
        }

        .edit {
          cursor: pointer;
          position: relative;
          top: 3px;
          margin-right: 7px;
          height: 25px;
        }
      }
   

      &.tasks-grid {
        .member-profile {
          width: 100%;
          display: flex;
          flex-flow: wrap;
          min-height: 43px;


          .name {
            margin: 0;
            width: calc(100% - 40px);
            margin-right: 15px;
            word-break: break-word;
          }

          .tag-info {
            width: 25px;
            height: 29px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
            background-color: $blue;
          }
        }

        .assign-status {
          width: 100%;
          margin: 16px 0 20px 0;
          display: flex;
          flex-flow: wrap;

          .assign-tag {
            background: #b1b7c0;
            border-radius: 6px;
            padding: 3px 8px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 21px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include large {
              font-size: 14px;
              border-radius: 8px;
            }
          }

          .assigned-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #efbf62;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 10px;
              border-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              border-radius: 50%;
         
              background-color: white;
            }
          }

          .not-assigned-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #b1b7c0;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 10px;
              border-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              border-radius: 50%;
             
              background-color: white;
            }
          }

          .done-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #71c49c;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 20px;
              border-radius: 8px;
            }
        

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              border-radius: 50%;
           
              background-color: white;
            }
          }

          .suggestion-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #40bbbd;
            color: white;
            display: flex;
            align-items: center;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 11px;
            }

            @include desktop2 {
              padding: 3px 10px;
            }

            @include large {
              padding: 4px 10px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              border-radius: 50%;
             
              background-color: white;
            }
          }

          .accept-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: 600;
            cursor: pointer;
            text-transform: uppercase;
            background: $blue;
            color: white;
            display: flex;
            align-items: center;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 11px;
            }

            @include desktop2 {
              padding: 3px 10px;
            }

            @include large {
              padding: 4px 10px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            .accept-icon {
              width: 24px;
            }

          }
        }

        .status-with-action {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          align-items: center;

          .status {
            width: 60%;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: $blue;

            @include mac {
              font-size: 14px;
            }

            @include large {
              font-size: 16px;
            }
          }

          .completed-tag-content {
            width: 60%;
          }

          .completed-tag {
            width: fit-content;
            padding: 4px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            text-transform: uppercase;

            @include mac {
              padding: 6px 10px;
              font-size: 13px;
            }

            @include large {
              font-size: 14px;
              padding: 10px 20px;
            }

            img {
              max-width: 20px;
              margin-right: 3px;
            }
          }
        }

        &:hover {
          .status-with-action {
            .status {
              color: white;
              a {
                color: white;
              }
            }
          }

          .assign-status {
            .assign-tag {
              background-color: #b1b7c0;
            }
          }
        }
      }
    }
  }
}

.filter-open-wrapper {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  background-color: $grey;
  border-radius: 6px;
  padding: 18px 25px;
  margin-bottom: 30px;

  @include large {
    border-radius: 8px;
    padding: 18px 35px;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: $black;
    margin-bottom: 16px;

    @include large {
      font-size: 20px;
    }
  }

  .filter-tags {
    display: flex;
    flex-flow: wrap;
    width: 100%;

    .btn {
      margin-right: 14px;
      margin-bottom: 14px;

      @include large {
        margin-right: 16px;
        margin-bottom: 16px;
      }

      @include small {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .btn:focus-visible,
    :not(.btn-check) + .btn:active {
      color: $tangerine;
    }
  }

  .btn-showTask-btn {
    height: 35px;
    border: 1px solid #4d60a4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 15px;
    color: $blue;
    width: fit-content;

    @include large {
      border-radius: 8px;
      font-size: 16px;
    }

    &.active,
    &:hover {
      color: white;
      background-color: $blue;
    }
  }

  .btn-suggestion-btn {
    height: 35px;
    border: 1px solid #40bbbd;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 15px;
    color: #40bbbd;
    width: fit-content;

    @include large {
      border-radius: 8px;
      font-size: 16px;
    }

    &.active,
    &:hover {
      color: white;
      background-color: #40bbbd;
    }
  }

  .btn-assigned-btn {
    height: 35px;
    border: 1px solid #efbf62;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 15px;
    color: #efbf62;
    width: fit-content;

    @include large {
      border-radius: 8px;
      font-size: 16px;
    }

    &.active,
    &:hover {
      color: white;
      background-color: #efbf62;
    }
  }

  .btn-done-btn {
    height: 35px;
    border: 1px solid #71c49c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 15px;
    color: #71c49c;
    width: fit-content;

    @include large {
      border-radius: 8px;
      font-size: 16px;
    }

    &.active,
    &:hover {
      color: white;
      background-color: #71c49c;
    }
  }

  .filter-by-search {
    width: 50%;

    @include small {
      width: 100%;
    }

    .search-bar {
      display: flex;
      flex-flow: wrap;
      width: 85%;
      height: 37px;
      margin-bottom: 16px;

      @include mac {
        // width: 258px;
      }

      @include large {
        height: 45px;
      }

      form {
        margin: 0;
        position: relative;
        width: 100%;
      }

      ._search {
        border: 0;
        position: absolute;
        right: 10px;
        top: 8px;
        background: transparent;
        max-width: 16px;

        @include large {
          right: 15px;
          top: 12px;
        }
      }

      input {
        width: 100%;
        padding: 0 42px 0 10px;
        border: 1px solid #ccd2db;
        height: 37px;

        @include large {
          height: 45px;
        }
      }
    }
  }

  .filter-by-tag {
    width: 50%;

    @include small {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.filter-search-tags-wrapper {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  background-color: $white;
  border-radius: 6px;
  margin-bottom: 30px;

  @include large {
    border-radius: 8px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      height: 35px;
      background: #b1b7c0;
      border-radius: 6px;
      width: fit-content;
      display: inline-flex;
      align-items: center;
      padding: 0px 16px;
      font-weight: 600;
      font-size: 15px;
      color: white;
      margin: 0 10px 10px 0;

      @include large {
        border-radius: 8px;
        font-size: 16px;
      }

      img {
        cursor: pointer;
        max-width: 14px;
        margin-left: 10px;
      }
    }
  }
}
._imgnew{
  width: 24px;
  height: 24px;
  margin-right: 5px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}