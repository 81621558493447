html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: $black;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-shadow: rgba(255, 255, 255, 0.01) 0 0 1px;
  text-shadow: rgba(255, 255, 255, 0.01) 0 0 1px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  a {
    color: inherit;
  }
}
a:hover, a:focus{
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: 0;

  &:active,
  &:focus,
  &:hover {
    outline: 0;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

:focus {
  outline: 0;
}

hr {
  margin: 0;
  border-left: none;
  border-right: none;
  border-top: none;
}

img,
picture {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    list-style-type: none;
  }
}

