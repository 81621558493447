.payment-information {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 30px;

    .payment-mode-info {
        padding-left: 30px;

        @include small {
            margin-top: 50px;
            padding-left: 10px;
        }

        @include mac {
            padding-left: 50px;
        }

        @include desktop {
            padding-left: 100px;
        }

        h2 {
            font-weight: 700;
            font-size: 21px;
            line-height: 28px;
            color: $black;

            @include large {
                font-size: 28px;
                line-height: 48px;
            }
        }
    }

    .choosen-plan {

        .grid-choosen-plan {
            width: calc(100%);
            position: relative;

            &:after {
                content: "";
                background-color: $grey;
                width: 102%;
                height: 102%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 10px;

            }

            .grid-choosen-plan-content {
                position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 10px;
                padding: 80px 20px;
                transition: all ease-in-out .3s;
                width: 100%;
                z-index: 2;
            }

            .choosen-top {
                background-color: $blue;
                text-transform: uppercase;
                font-size: 13px;
                color: white;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                padding: 6px;

            }

            .save {
                border-radius: 6px 0 0 6px;
                background-color: $blue;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                padding: 6px 10px;
                color: white;
                position: absolute;
                top: -10px;
                right: -20px;
            }

            .intro {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                h2 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    color: $black;

                    @include large {
                        font-size: 32px;
                        line-height: 48px;
                    }
                }


            }

            .price {
                opacity: 0.96;
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
                color: $black;
                margin-top: 10px;

                @include large {
                    font-size: 32px;
                    line-height: 48px;
                }

                span {
                    font-weight: 400;

                    &.cm {
                        font-size: 20px;
                        line-height: 24px;

                        @include large {
                            font-size: 24px;
                            line-height: 36px;
                        }
                    }

                }
            }

            ul {
                margin: 28px 0 0 0;
                padding: 0;

                @include large {
                    margin: 32px 0 0 0;

                }

                li {
                    line-height: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: $black;
                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 26px;

                    @include mac {
                        font-size: 16px;
                    }

                    @include large {
                        font-size: 24px;
                        padding-left: 26px;
                        margin-bottom: 16px;

                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 5px;
                        background: url('/assets/images/iconTick.svg');
                        width: 17px;
                        height: 15px;
                        background-size: 85%;
                        background-repeat: no-repeat;

                        @include large {
                            width: 17px;
                            height: 15px;
                            top: 11px;
                            background-size: 100%;
                        }
                    }
                }
            }
        }
    }
}