.member-list-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 20px;

  @include large {
    margin-top: 50px;
  }
  .fitler-wrapper {
    justify-content: center;
  }

  .search-bar {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;

    form {
      margin: 0;
      position: relative;
      display: flex;
      align-items: center;
    }

    ._search {
      border: 0;
      position: absolute;
      right: 15px;
      background: transparent;
    }

    input {
      width: 550px;
      padding: 0 21px;
      border: 1px solid #ccd2db;

      @include small {
        width: 430px;
      }

      @include large {
        width: 648px;
        padding: 0 30px;
      }
    }
  }

  .member-list-content {
    display: flex;
    flex-flow: wrap;
    width: calc(100% + 20px);
    max-height: 55vh;
    overflow: auto;
    position: relative;
    margin-top: 20px;
    margin-left: -10px;
    margin-right: -10px;

    @include mac {
      margin-top: 30px;
    }

    @include large {
      margin-top: 48px;
    }

    // .bottom-graidient {
    //     background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.08%, rgba(255, 255, 255, 0.927083) 44.27%, #FFFFFF 72.4%);
    //     border: solid 1px red;
    //     position: absolute;
    //     bottom: 0;
    //     height: 50px;
    //     width: 100%;
    //     left: 0;
    // }

    .list-grid {
      background: linear-gradient(159.74deg, #f0f5fa 4.12%, #ffffff 118.31%);
      border-radius: 12px;
      width: calc(100% / 2 - 20px);
      margin-right: 10px;
      margin-left: 10px;
      padding: 20px 16px;
      display: flex;
      flex-flow: wrap;
      transition: all ease-in-out 0.3s;
      margin-bottom: 22px;
      align-items: flex-start;

      @include small {
        width: calc(100% / 2 - 20px);
      }

      @include mac {
        margin-bottom: 25px;
        width: calc(100% / 3 - 20px);
      }
      @include desktop {
        margin-bottom: 25px;
        width: calc(100% / 4 - 20px);
      }

      @include large {
        border-radius: 16px;
        width: calc(100% / 4 - 20px);
        padding: 20px 24px;

      }

      &:hover {
        background: linear-gradient(120.3deg, #f7a072 43.91%, #ffffff 126.57%);

        .member-profile {
          .name {
            color: $white;
          }
        }

        .email {
          a {
            color: $white;
          }

          color: $white;
        }

        path {
          fill: white !important;
        }

        .position {
          color: white;
        }

        .action-btn .delete {
          ._delete-icon-hover {
            display: block;
          }

          ._delete-icon {
            display: none;
          }
        }
      }

      .member-profile {
        width: 60%;

        ._image {
          width: 64px;
          height: 64px;
          position: relative;
          border-radius: 50%;
          background-color: $white;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            border-radius: 50%;
          }

          &:after {
            content: "";
            background: url("/assets/images/Badge.svg");
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 20px;
            height: 20px;
          }
        }

        .name {
          font-weight: 600;
          font-size: 15px;
          color: $black;
          margin-top: 8px;
          min-height: 45px;
          @include desktop {
            font-size: 17px;
          }

          @include large {
            font-size: 19px;
            min-height: 50px;
          }
        }
      }

      .action-btn {
        width: 40%;
        display: flex;
        justify-content: flex-end;

        .delete {
          cursor: pointer;
          position: relative;
          right: -5px;
          height: 25px;

          ._delete-icon-hover {
            display: none;
            position: absolute;
            max-width: 22px;
            position: relative;
            top: 3px;
          }

          ._delete-icon {
            position: absolute;
            max-width: 22px;
            position: relative;
            top: 3px;
            display: block;
          }
        }

        .edit {
          cursor: pointer;
          position: relative;
          top: 3px;
          margin-right: 7px;
          height: 25px;
        }
      }

      .position {
        text-transform: capitalize;
        padding-top: 12px;
        padding-bottom: 0px;
        min-height: 31px;
        width: 100%;
        font-size: 13px;
        font-weight: 500;
      }

      .email {
        width: 100%;
        color: $blue;
        font-weight: 600;
        font-size: 12.6px;
        margin-top: 8px;
        word-break: break-word;

        @include large {
          font-size: 14px;
        }

        a {
          color: $blue;
          text-decoration: underline;
        }
      }

      &.tasks-grid {
        .member-profile {
          width: 100%;
          display: flex;
          flex-flow: wrap;

          .name {
            margin: 0;
            width: calc(100% - 40px);
            margin-right: 15px;
            word-break: break-word;
          }

          .tag-info {
            width: 25px;
            height: 29px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
            background-color: $blue;
          }
        }

        .assign-status {
          width: 100%;
          margin: 16px 0 20px 0;
          display: flex;
          flex-flow: wrap;

          .assign-tag {
            background: #b1b7c0;
            border-radius: 6px;
            padding: 4px 8px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 21px;
            color: white;

            @include large {
              font-size: 14px;
              border-radius: 8px;
            }
          }

          .assigned-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #efbf62;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 10px;
              border-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              margin-right: 5px;
              border-radius: 50%;
              background-color: white;
            }
          }

          .not-assigned-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #b1b7c0;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 10px;
              border-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              margin-right: 5px;
              border-radius: 50%;
          
              background-color: white;
            }
          }

          .done-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #71c49c;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 12px;
            }

            @include large {
              font-size: 14px;
              padding: 4px 20px;
              border-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              margin-right: 5px;
              border-radius: 50%;
           
              background-color: white;
            }
          }

          .suggestion-tag {
            width: fit-content;
            padding: 3px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: uppercase;
            background: #40bbbd;
            color: white;
            display: flex;
            align-items: center;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 11px;
            }

            @include desktop2 {
              padding: 3px 10px;
            }

            @include large {
              padding: 4px 10px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            img {
              width: 100%;
              height: 100%;
              background-size: cover;
              margin-right: 5px;
              border-radius: 50%;
        
              background-color: white;
            }
          }

          .accept-tag {
            width: fit-content;
            padding: 4px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: 600;
            cursor: pointer;
            text-transform: uppercase;
            background: $blue;
            color: white;
            display: flex;
            align-items: center;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            height: 32px;

            @include mac {
              padding: 3px 10px;
              font-size: 11px;
            }

            @include desktop2 {
              padding: 3px 10px;
            }

            @include large {
              padding: 3px 10px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            .accept-icon {
              width: 24px;
            }
          }
        }

        .status-with-action {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          .status {
            width: 60%;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: $blue;

            @include mac {
              font-size: 14px;
            }

            @include large {
              font-size: 16px;
            }

            a {
              color: $blue;
            }
          }

          .completed-tag-content {
            width: 60%;
          }

          .completed-tag {
            width: fit-content;
            padding: 4px 10px;
            font-size: 12px;
            letter-spacing: 0px;
            text-transform: uppercase;
            max-width: 60%;

            @include mac {
              padding: 6px 10px;
              font-size: 13px;
            }

            @include large {
              font-size: 14px;
              padding: 10px 20px;
            }

            img {
              max-width: 20px;
              margin-right: 3px;
            }
          }
        }

        &:hover {
          .status-with-action {
            .status {
              color: white;

              a {
                color: white;
              }
            }
          }

          .assign-status {
            .assign-tag {
              background-color: #b1b7c0;
            }
          }
        }
      }
    }
  }
}
