// =============================================================================
//	MEDIA QUERIES
// =============================================================================
$small: 992px;
$tablet: 767px;
$mobile: 767px;

$mac: 1100px;
$desktop: 1300px;
$desktop2: 1400px;
$large: 1700px;

@mixin small {
    @media (max-width: $small) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }   
}
@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin mac {
    @media (min-width: $mac) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin desktop2 {
    @media (min-width: $desktop2) {
        @content;
    }
}

@mixin large {
    @media (min-width: $large) {
        @content;
    }
}