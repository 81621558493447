.team-task-information-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;

  h2 {
    font-weight: 700;
    font-size: 22px;
    color: $black;
    margin-bottom: 15px;
    @include small {
      font-size: 18px;
    }

    @include large {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    h3 {
      font-weight: 700;
      font-size: 26px;
      color: $black;
      margin-bottom: 15px;

      @include large {
        font-size: 30px;
        margin-bottom: 20px;
      }
      span {
        color: $tangerine;
      }
    }

    .btn-seeAll {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      color: $tangerine;
      border: 0;

      @include desktop2 {
        font-size: 16px;
      }

      @include large {
        font-size: 18px;
      }

      &:hover {
        color: $tangerine;
      }
    }
  }

  .information-right-panel {
    @include mac {
      padding-left: 30px;
    }

    .task-info-content {
      width: 100%;
      display: flex;
      flex-flow: wrap;
    }

    .total-task {
      width: 200px;
      @include small {
        width: 140px;
      }

      .total-counttext {
        box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
        border-radius: 8px;
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        flex-flow: wrap;
        font-weight: 600;
        font-size: 60px;
        color: $black;
        min-height: 150px;

        @include small {
          width: 140px;
          padding: 20px 10px;
        }
        @include large {
          font-size: 82px;
        }
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
      color: $black;
      width: 100%;
      text-align: center;
      padding: 0;
      text-transform: uppercase;
    }

    .sub-task-info-outer {
      width: calc(100% - 230px);
      margin-right: 30px;

      @include small {
        width: calc(100% - 150px);
        margin-right: 10px;
      }
    }
    .sub-task-info {
      box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 8px;
      width: calc(100%);
      background-color: white;
      display: flex;
      align-items: center;
      padding: 20px;
      min-height: 150px;

      @include small {
        width: calc(100%);
        padding: 20px 10px;
      }

      .grid {
        width: calc(100% / 3);
        display: flex;
        flex-flow: wrap;

        .count-with-progress {
          display: flex;
          justify-content: center;
          width: 100%;

          span {
            font-weight: 600;
            font-size: 60px;
            color: $black;

            @include large {
              font-size: 82px;
            }
          }

          position: relative;
        }

        .barcontainer {
          background-color: white;
          position: relative;
          transform: translateY(-50%);
          top: 50%;
          margin-right: 10px;
          width: 16px;
          height: 75px;
          float: left;
          margin-top: -18px;
          border-radius: 6px;
          overflow: hidden;

          //border darken(#98AFC7, 40%) 3px solid
          @include large {
            margin-top: -9px;
            width: 20px;
            border-radius: 8px;
          }
        }

        .bar {
          background: #b1b7c0;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 80%;
          //border-top: 6px solid #FFF;
          box-sizing: border-box;
          animation: grow 1.5s ease-out forwards;
          transform-origin: bottom;
          border-radius: 6px;

          @include large {
            border-radius: 8px;
          }
        }

        @keyframes grow {
          from {
            transform: scaleY(0);
          }
        }

        p {
          font-weight: 600;
          font-size: 14px;
          color: $black;
          width: 100%;
          text-align: center;
          padding: 0;
          text-transform: uppercase;

          @include large {
            font-size: 16px;
          }
        }
      }
    }

    .team-allocation-wrapper {
      box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 8px;
      width: 100%;
      background-color: white;
      margin-bottom: 32px;
      padding: 30px;

      @include large {
        padding: 40px;
      }
      .taskrepeater {
        display: flex;
        flex-flow: wrap;
        display: flex;
        flex-flow: wrap;
        margin-bottom: 30px;
        align-items: flex-start;
        &:last-child {
          margin-bottom: 0;
        }
        .sizegrid-content {
          display: flex;
          flex-flow: wrap;
          width: 125px;

          .count-with-progress {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            span {
              font-weight: 600;
              font-size: 60px;
              color: $black;
              display: flex;
              align-items: flex-end;

              @include large {
                font-size: 82px;
              }
            }

            position: relative;
          }

          .barcontainer {
            background-color: white;
            position: relative;
            transform: translateY(-50%);
            top: 50%;
            margin-right: 10px;
            width: 16px;
            height: 75px;
            float: left;
            margin-top: -15px;
            border-radius: 6px;
            overflow: hidden;

            //border darken(#98AFC7, 40%) 3px solid
            @include large {
              margin-top: -9px;
              width: 20px;
              border-radius: 8px;
            }
          }

          .bar {
            background: #b1b7c0;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 80%;
            //border-top: 6px solid #FFF;
            box-sizing: border-box;
            animation: grow 1.5s ease-out forwards;
            transform-origin: bottom;
            border-radius: 6px;

            @include large {
              border-radius: 8px;
            }
          }

          @keyframes grow {
            from {
              transform: scaleY(0);
            }
          }

          p {
            font-weight: 600;
            font-size: 14px;
            color: $black;
            width: 100%;
            text-align: left;
            padding: 0;
            text-transform: uppercase;

            @include large {
              font-size: 16px;
            }
          }
        }
      }

      .allocation-content-wrapper {
        display: flex;
        flex-flow: wrap;
        width: calc(100% - 125px + 10px);
        margin: 0 -5px;
        // max-height: 135px;
        // overflow: auto;
        padding-right: 5px;
        @include small {
          max-height: 128px;
        }

        .allocation-list-grid {
          background: #f6f8fb;
          border-radius: 12px;
          margin-bottom: 15px;
          width: calc(100% / 2 - 10px);
          padding: 20px 15px;
          display: flex;
          flex-flow: wrap;
          transition: all ease-in-out 0.3s;
          margin-bottom: 10px;
          margin-right: 5px;
          margin-left: 5px;

          @include mac {
          }

          @include desktop2 {
            margin-bottom: 10px;
            width: calc(100% / 3 - 10px);
          }

          @include desktop2 {
            margin-bottom: 10px;
            width: calc(100% / 3 - 10px);
          }

          @include large {
            margin-bottom: 20px;
            border-radius: 16px;
          }

          .member-profile {
            width: 100%;
            display: flex;
            flex-flow: wrap;

            .name {
              margin: 0;
              width: calc(100% - 40px);
              margin-right: 15px;
              font-weight: 600;
              font-size: 15px;
              color: $black;
              word-wrap: break-word;
              @include small {
                font-size: 13px;
              }

              @include desktop {
                font-size: 16px;
              }

              @include large {
                font-size: 20px;
              }
            }

            .tag-info {
              width: 25px;
              height: 29px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              text-transform: uppercase;
              background-color: $blue;
            }
          }

          .assign-status {
            width: 100%;
            margin: 16px 0 0px 0;
            display: flex;
            flex-flow: wrap;

            .not-assign-tag {
              background: #b1b7c0;
              border-radius: 6px;
              padding: 3px 8px;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 12px;
              line-height: 21px;
              color: white;
              display: flex;
              align-items: center;
              height: 32px;

              @include large {
                font-size: 14px;
                border-radius: 8px;
              }
            }

            .assigned-tag {
              width: fit-content;
              padding: 3px 10px;
              font-size: 13px;
              letter-spacing: 0px;
              font-weight: 600;
              text-transform: uppercase;
              background: #efbf62;
              border-radius: 6px;
              color: white;
              display: flex;
              align-items: center;
              height: 32px;

              @include mac {
                padding: 3px 10px;
                font-size: 12px;
              }

              @include large {
                font-size: 14px;
                padding: 4px 10px;
                border-radius: 8px;
              }

              img {
                width: 25px;
                margin-right: 5px;
                border-radius: 50%;
                height: 25px;
                background-color: white;
              }
            }

            .done-tag {
              width: fit-content;
              padding: 3px 10px;
              font-size: 13px;
              letter-spacing: 0px;
              font-weight: 600;
              text-transform: uppercase;
              background: #71c49c;
              border-radius: 6px;
              color: white;
              display: flex;
              align-items: center;
              height: 32px;

              @include mac {
                padding: 3px 10px;
                font-size: 12px;
              }

              @include large {
                font-size: 14px;
                padding: 4px 20px;
                border-radius: 8px;
              }

              img {
                width: 25px;
                margin-right: 5px;
                border-radius: 50%;
                height: 25px;
                background-color: white;
              }
            }

            .suggestion-tag {
              width: fit-content;
              padding: 3px 10px;
              font-size: 12px;
              letter-spacing: 0px;
              font-weight: 600;
              text-transform: uppercase;
              background: #40bbbd;
              color: white;
              display: flex;
              align-items: center;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              height: 32px;

              @include mac {
                padding: 3px 10px;
                font-size: 12px;
              }

              @include desktop {
                padding: 3px 10px;
                font-size: 11px;
              }

              @include desktop2 {
                padding: 3px 10px;
              }

              @include large {
                padding: 4px 10px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }

              img {
                width: 25px;
                margin-right: 5px;
                border-radius: 50%;
                height: 25px;
                background-color: white;
              }
            }

            .accept-tag {
              width: fit-content;
              padding: 4px 10px;
              font-size: 12px;
              letter-spacing: 0px;
              font-weight: 600;
              cursor: pointer;
              text-transform: uppercase;
              background: $blue;
              color: white;
              display: flex;
              align-items: center;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              height: 32px;

              @include mac {
                padding: 3px 10px;
                font-size: 11px;
              }

              @include desktop {
                padding: 3px 10px;
                font-size: 11px;
              }

              @include desktop2 {
                padding: 3px 10px;
              }

              @include large {
                padding: 4px 10px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
              .accept-icon {
                width: 24px;
             }
            }
          }

          .status-with-action {
            display: flex;
            flex-flow: wrap;
            width: 100%;
            align-items: center;

            .status {
              width: 60%;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              text-transform: uppercase;
              color: $blue;

              @include mac {
                font-size: 14px;
              }

              @include large {
                font-size: 16px;
              }
            }

            .completed-tag-content {
              width: 60%;
            }

            .completed-tag {
              width: fit-content;
              padding: 4px 10px;
              font-size: 12px;
              letter-spacing: 0px;
              text-transform: uppercase;

              @include mac {
                padding: 6px 10px;
                font-size: 13px;
              }

              @include large {
                font-size: 14px;
                padding: 10px 20px;
              }

              img {
                max-width: 20px;
                margin-right: 3px;
              }
            }

            .action-btn {
              width: 40%;
              display: flex;
              justify-content: flex-end;

              .delete {
                cursor: pointer;
                position: relative;
                right: -5px;
                height: 25px;
              }

              .edit {
                cursor: pointer;
                position: relative;
                top: 3px;
                margin-right: 7px;
                height: 25px;
              }
            }
          }

          &:hover {
            .status-with-action {
              .status {
                color: white;
              }
            }

            .assign-status {
              .assign-tag {
                background-color: #b1b7c0;
              }
            }
          }

          &:hover {
            background: linear-gradient(
              120.3deg,
              #f7a072 43.91%,
              #ffffff 126.57%
            );

            .member-profile {
              .name {
                color: $white;
              }
            }

            .email {
              a {
                color: $white;
              }

              color: $white;
            }

            path {
              fill: white !important;
            }
          }
        }
      }
    }
  }

  .information-left-panel {
    margin-bottom: 30px;
    @include mac {
      margin-bottom: 0;
    }
    .dimension-graph {
      padding: 40px 0;
    }
    .profile-info {
      display: flex;
      // flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;

      @include large {
        border-radius: 16px;
      }

      ._profile-img {
        width: 50px;
        height: 50px;
        position: relative;
        background-color: #f1eeee;
        border-radius: 50%;

        @include large {
          width: 50px;
          height: 50px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .badges {
          position: absolute;
          bottom: 0;
          right: 0;
          width: auto;
          height: auto;
        }
      }

      .info-user {
        // width: calc(100% - 142px);
        padding-left: 15px;

        @include large {
          width: calc(100% - 156px);
          padding-left: 20px;
        }

        .name {
          font-weight: 600;
          font-size: 16px;
          color: $black;
          width: 100%;
          margin: 0px 0;

          @include large {
            font-size: 21px;
          }
        }

        .designation {
          font-weight: 400;
          font-size: 14px;
          color: $black;
          width: 100%;
          padding-bottom: 3px;
          padding-top: 6px;

          @include large {
            font-size: 16px;
          }
        }

        // .email {
        //     font-weight: 500;
        //     font-size: 14px;
        //     color: $black;

        //     @include large {
        //         font-size: 16px;

        //     }
        // }
      }

      .btn-edit {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: $tangerine;
        border: 0;
        padding: 0;

        @include desktop2 {
          font-size: 15px;
        }

        @include large {
          font-size: 16px;
        }

        &:hover {
          color: $tangerine;
        }
      }
    }

    .team-skills-wrapper {
      box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 8px;
      width: 100%;
      background-color: white;
      padding: 30px;
      &.member {
        padding-right: 10px;
        padding-left: 10px;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          display: inline-flex;
          align-items: center;
          width: 100%;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            width: 170px;

            @include large {
              width: 240px;
            }
          }

          .progress-bg-text {
            width: calc(100% - 170px);
            padding-left: 10px;

            @include large {
              width: calc(100% - 240px);
            }

            .progress {
              background-color: $grey;
              height: 24px;
              border-radius: 20px;

              .progress-bar {
                background-color: $tangerine;
                border-radius: 10px;
                font-size: 14px;
                min-width: 35px;
              }
            }
          }
        }
      }
    }
  }
}
