.questionnaire-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 50px;
    justify-content: center;

    @include large {
        margin-top: 100px;

    }

    .questionnaire-grid {
        border-radius: 12px;
        padding: 10px;
        margin: 0 5px;

        @include mac {
            padding: 30px;
            margin: 0 16px;

        }

        ._image {}

        ._content {
            @include small {
                margin-top: 20px;
            }

            p {
                padding-bottom: 30px;
                font-weight: 500;

                @include large {
                    padding-bottom: 40px;

                }

                &:last-child {
                    padding-bottom: 0;

                    @include mac {
                        padding-bottom: 40px;

                    }
                }
            }

        }

        .btn-wrapper {
            margin-top: 30px;
            justify-content: flex-start;
        }

    }
    .manage-team-skill-info{
        display: flex;
        align-items: flex-end;
        height: 100%;
        flex-flow: wrap;
        .skill-info-text{
            @include small {
                margin: 0 auto;
                text-align: center;
            }

        }
        p{
            width: 285px;
            font-size: 16px;

            @include mac {
                font-size: 17px;
            }
        
            @include desktop2 {
                font-size: 17px;
            }
        
            @include large {
                font-size: 22px;
            }

        }
        .btn-wrapper{
            margin-top: 20px;

        }
        .btn-wrapper .btn-primary {
            width: 285px;
        }
    }

}

.greyed-out {
    opacity: 0.3;
}