.choose-plan-wrapper {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    border-radius: 10px;
    margin-top: 50px;
    padding: 30px 30px;

    @include mac {
        padding: 20px 60px;
        min-height: 650px;

    }

    @include desktop {
        padding: 20px 80px;
    }

    @include large {
        margin-top: 64px;
        padding: 23px 100px;
        border-radius: 16px;
        min-height: 900px;

    }

    .grid-plan {
        width: calc(100% / 3 - 14px);
        background-color: white;
        border-radius: 10px;
        padding: 60px 20px;
        margin-right: 20px;
        border: Solid 4px white;
        transition: all ease-in-out .3s;
        position: relative;
        overflow: hidden;
        @include small {
          width: calc(100%);
          margin-right: 0px;
          margin-bottom: 30px;
 
        }

        @include desktop {
            width: calc(100% / 3 - 22px);
            margin-right: 32px;

        }

        @include large {
            width: calc(100% / 3 - 32px);
            border-radius: 16px;
            padding: 64px 32px;
            margin-right: 32px;
            border: Solid 6px white;

        }

        &.active, &:hover {
            border-color: $blue;
            padding: 90px 20px;

            @include large {
                padding: 110px 32px;

            }
        }

        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }

        .save {
            border-radius: 0px 0 0 8px;
            background-color: $blue;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding: 6px 10px;
            color: white;
            position: absolute;
            top: 0;
            right: 0;
        }

        .intro {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                color: $black;

                @include large {
                    font-size: 32px;
                    line-height: 48px;
                }
            }

            .price {
                opacity: 0.96;
                font-size: 24px;
                line-height: 28px;
                font-weight: 700;
                color: $black;

                @include large {
                    font-size: 32px;
                    line-height: 48px;
                }

                span {
                    font-weight: 400;

                    &.cm {
                        font-size: 20px;
                        line-height: 24px;

                        @include large {
                            font-size: 24px;
                            line-height: 36px;
                        }
                    }

                }
            }
        }

        ul {
            margin: 28px 0;
            padding: 0;

            @include large {
                margin: 32px 0;

            }

            li {
                line-height: normal;
                font-weight: 400;
                font-size: 14px;
                color: $black;
                position: relative;
                margin-bottom: 10px;
                padding-left: 26px;
                @include mac {
                    font-size: 16px;
                    }
                @include large {
                    font-size: 24px;
                    padding-left: 26px;
                    margin-bottom: 16px;

                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background: url('/assets/images/iconTick.svg');
                    width: 17px;
                    height: 15px;
                    background-size: 85%;
                    background-repeat: no-repeat;

                    @include large {
                        width: 17px;
                        height: 15px;
                        top: 11px;
                        background-size: 100%;
                    }
                }
            }
        }

        .btn-primary {
            border-radius: 8px;
            font-weight: 700;
            font-size: 16px;
            height: auto;
            padding: 10px;
            @include mac {
            padding: 14px;
            }
            @include desktop {
                font-size: 16px;
            }

            @include large {
                font-size: 18px;
                height: auto;
                padding: 16px;

            }
        }
    }
}