.payment-history-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 50px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 290px);
    overflow: auto;

    @include large {
        margin-top: 100px;
    }

    .table-history {
        width: 100%;
        max-height: 100%;
        padding-right: 10px;

        @include mac {
            padding-right: 30px;

        }

        table {
            width: 100%;
            border: 0;

            thead {
                position: sticky;
                top: 0;
                background-color: white;
                z-index: 8;

                th {
                    font-weight: 500;
                    font-size: 16px;
                    columns: $black;
                    padding: 0px 10px 20px 10px;
                    position: relative;
                    padding-bottom: 25px;

                    @include mac {
                        padding: 0px 30px 20px 30px;

                    }

                    .arrow {
                        width: 10px;
                        height: 12px;
                        position: absolute;
                        margin-left: 10px;
                    }

                    img {
                        vertical-align: top;
                    }

                    .toparrow {
                        position: relative;
                        top: 4px;
                        cursor: pointer;
                    }

                    .downarrow {
                        position: relative;
                        bottom: 8px;
                        cursor: pointer;
                    }
                }
            }

            tbody {
                tr {
                    background-color: #F6F8FB;

                    &:nth-child(even) {
                        background-color: white;
                    }
                }

                td {
                    font-weight: 400;
                    font-size: 16px;
                    columns: $black;
                    padding: 20px 10px;

                    @include mac {
                        padding: 20px 30px;

                    }

                }
            }
        }
    }



}