.pre-creation-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 50px;
    justify-content: center;

    @include large {
        margin-top: 100px;

    }

    .creation-grid {
        background: #FFFFFF;
        box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
        border-radius: 12px;
        padding: 40px;
        margin: 0 16px;
        position: relative;
        &.fade {
            pointer-events: none;
            opacity: 0.8;
            background: #fafafa;
        }

        @include mac {
            padding: 40px;
        }

        @include large {
            padding: 78px;
            border-radius: 16px;
        }

        @include small {
            margin-bottom: 32px;
            padding: 40px;

        }
        .completed-tag{
            position: absolute;
            top: 30px;
            left: 0;
            @include mac {
            top: 50px;

            }
            @include large {
                top: 90px;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 21px;
            color: $black;
            margin: 24px 0 14px 0;
            padding: 0;

            @include mac {
                font-size: 24px;
            }

            @include large {
                font-size: 35px;
                margin: 44px 0 24px 0;

            }

        }

        .icon-img {
            height: 56px;
            width: 100%;
            img{
                max-width: 43px;
                @include large {
                    max-width: 100%;
                }
            }
        }

        p {
            padding-bottom: 38px;
            padding-right: 50px;

            @include large {
                padding-bottom: 48px;

            }
        }
    }

}