.contactus-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 35px;

    .left-image {

        display: none;
        align-items: flex-end;

        @include mac {
            display: flex;

        }
    }

    .right-image {
        display: none;
        align-items: flex-end;
        justify-content: center;

        @include mac {
            display: flex;

        }
    }

    .contactus-form {
        background: #F6F8FB;
        border-radius: 12px;
        margin: 0 auto;
        display: flex;
        flex-flow: wrap;

        @include large {
            border-radius: 16px;

        }
        form{
            margin: 0;
            width: 100%;
            padding: 40px;

        }
    }
}