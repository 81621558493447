// @import '../config/variables';

form {
  margin-top: 32px;

  .form-field {
    margin-bottom: 24px;
    position: relative;
  }

  .form-check-input {
    width: 23px;
    height: 23px;
    border: solid 2px $blue;
  }

  .form-check-input:focus {
    border-color: $blue;
    outline: 0;
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: $blue;
    border: solid 2px $blue;
    width: 23px;
    height: 23px;
  }

  .input-group2 {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    .react-datepicker-wrapper{
      width: 100%;
      position: relative;
      &:after{
          content: "";
          background: url('/assets/images/calendar.png');
          width: 22px;
          position: absolute;
          right: 9px;
          top: 13px;
          height: 22px;
          opacity: 0.6;
          @include large {
            right: 15px;
            top: 21px;
          }
      }
    }

    .input-group-prepend {
      position: absolute;
      left: 12px;

      @include large {
        left: 15px;
      }
    }
  }

  .form-control {
    background: #ffff;
    border: 2px solid #cdd5df;
    border-radius: 8px;
    height: 50px;
    padding: 0 12px 0 45px;
    margin: 0;
    font-size: 14px;
    color: $black;

    @include large {
      height: 64px;
    }

    &.spacing-equal {
      padding: 12px;
    }
  }

  .react-tel-input .form-control {
    background: #ffff;
    border: 2px solid #cdd5df;
    border-radius: 8px;
    height: 50px;
    padding: 0 12px 0 45px;
    margin: 0;
    font-size: 14px;
    color: $black;
    width: 100%;

    @include large {
      height: 64px;
    }
  }

  .text-area,
  textarea {
    background: #ffff;
    border: 2px solid #cdd5df;
    border-radius: 8px;
    height: 150px !important;
    padding: 12px;
    margin: 0;
    font-size: 14px;
    color: $black;
    resize: none;

    @include large {
      height: 210px !important;
    }
  }

  .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin: 0 0 0 12px;

    @include large {
      margin: 0 0 0 15px;
    }

    ._img {
      position: relative;
      max-width: 22px;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
  }

  .error {
    font-size: 11px;
    color: $error;
    padding-left: 12px;
    position: relative;
    bottom: -4px;
    text-align: left;
    display: flex;
  }
}

.file-input {
  width: 238px;
  position: relative;
  margin-left: 24px;
  display: flex;

  span {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: $tangerine;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: white;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;

    @include large {
      font-size: 16px;
    }
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.password-field {
  position: relative;
  input::placeholder {
    position: relative;
    top: 3px;
  }
  .iconEyes {
    position: absolute;
    top: 30px;
    width: 48px;
    right: 6px;
    cursor: pointer;
    opacity: 1;
    background-color: white;
    padding: 8px;
    height: 38px;
    @include large {
      top: 30px;
      width: 58px;
      right: 11px;
      height: 51px;
    }
    &.visible {
    }
  }
  // .iconEyes2 {
  //     position: absolute;
  //     top: 37px;
  //     max-width: 26px;
  //     right: 14px;
  //     cursor: pointer;
  //     opacity: 0;
  //     @include large {
  //         top: 42px;
  //         max-width: 28px;
  //         right: 17px;
  //     }
  // }
}

.file-input-browse {
  width: 100%;
  position: relative;

  .info-size {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #b1b7c0;
    margin-top: 14px;

    @include large {
      font-size: 16px;
    }
  }

  .file-drag {
    width: 100%;
    height: 240px;
    background-color: white;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: white;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px dashed rgba(77, 96, 164, 0.5);
    margin-top: 24px;
    position: relative;

    @include mac {
      height: 273px;
    }

    @include large {
      height: 325px;
    }

    .drag-content {
      width: 100%;
      text-align: center;

      ._icon-drag {
        width: 64px;
        margin: 0 auto 22px auto;

        @include large {
          margin: 0 auto 32px auto;
        }
      }

      p {
        font-size: 18px;
        color: $black;
        letter-spacing: 0.03em;

        @include large {
          font-size: 20px;
        }
      }

      span {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        color: $blue;
        letter-spacing: 0.03em;
      }
    }
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

.redio-wrap {
  display: flex;
  align-items: flex-end;
  padding-left: 15px;
  margin-top: 10px;
  padding-bottom: 13px;

  label input[type="radio"] {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
  }

  label:nth-child(2) input[type="radio"] ~ span {
    height: 60px;
  }

  label:nth-child(3) input[type="radio"] ~ span {
    height: 60px;
  }

  label input[type="radio"] ~ span {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    width: 44px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $black;
  }

  label input[type="radio"] ~ span:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    margin-right: 5px;
    border: 1px solid $blue;
    border-radius: 6px;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;
    color: white;
  }

  p {
    position: absolute;
    font-size: 18px;
    margin: 0 !important;
  }

  label input[type="radio"]:checked ~ span:before {
    background-color: $blue;
    color: white;
  }

  label input[type="radio"]:checked ~ span p {
    color: white;
  }
}
.password-ul {
  padding-left: 15px;
  li {
    list-style: disc;
  }
}
.info-icon-image {
  &._img {
    position: relative;
    max-width: 22px;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}

.rs-picker-date-menu {
  z-index: 9999 !important;
}

.rs-picker-toggle-value {
  color: $black !important;
}
