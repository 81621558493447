.progressbar-with-heading {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  @include large {
    margin-top: 60px;
  }

  h3 {
    font-weight: 600;
    color: $black;
    font-size: 20px;
    width: 100%;

    @include mac {
      font-size: 24px;
    }

    @include large {
      font-size: 32px;
    }

    span {
      font-weight: 400;
    }
  }

  .progress {
    width: 380px;
    height: 22px;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 15px;

    @include mac {
      width: 500px;
      height: 30px;
      border-radius: 6px;
      font-size: 16px;
      margin-top: 20px;
    }

    @include large {
      width: 600px;
      height: 48px;
      border-radius: 8px;
      font-size: 20px;
      margin-top: 32px;
    }

    .progress-bar {
      background-color: $green;
    }
  }
}

.requirement-task-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  overflow: auto;
  max-height: 47vh;
  padding-right: 10px;

  @include large {
    margin-top: 64px;
  }
  .questionlist {
    display: flex;
    width: 800px;
    margin: 0 auto;
    flex-flow: wrap;
    padding: 20px 0;
    .big-font {
      font-weight: 600;
    }
    ul {
      width: 100%;
      li {
        width: 100%;
        padding-top: 20px;
        padding-left: 5px;
        label {
          position: relative;
          top: 3px;
        }
      }
    }
  }
  .questionlist ~ .flex {
    width: 800px;
    margin: 40px auto auto auto;
    display: flex;
    .add-btn {
      width: 190px !important;

      @include mac {
        width: 200px !important;
      }

      @include large {
        font-size: 16px;
        width: 200px;
        height: 45px;
      }
    }
    .btn-link {
      border-radius: 8px;
      font-weight: 700;
      font-size: 15px;
      width: 190px;
      height: 45px;
      text-transform: uppercase;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: solid 1px;
      margin: 0 20px 0 0;

      @include mac {
        width: 200px;
        margin: 0 20px 0 0;
      }

      @include large {
        font-size: 16px;
        width: 200px;
        height: 45px;
        margin: 0 20px 0 0;
      }
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: transparent;
  }

  .table {
    border: 0;

    tr {
      border: 0;
    }

    th {
      border: 0;
      background-color: $grey;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.03em;
      padding: 15px 10px;
      color: $black;
      text-align: center;
      vertical-align: text-bottom;
      @include mac {
        font-size: 15px;
      }

      @include large {
        font-size: 18px;
      }

      &:nth-of-type(1) {
        width: 3%;
      }

      &:nth-of-type(2) {
        width: 40%;
      }
    }

    td {
      border: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 15px 10px;
      color: $black;
      vertical-align: middle;
      &:nth-child(1) {
        vertical-align: top;
      }
      div {
        text-align: center;
      }

      &.big-font {
        font-weight: 600;
        font-size: 13px;
        @include mac {
          font-size: 16px;
        }
        @include large {
          font-size: 22px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #c9ced6;
      }
    }
    thead {
      position: sticky;
      top: 0;
    }

    form {
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }
}
