 .choose-skills-wrapper {
     display: flex;
     flex-flow: wrap;
     width: 100%;
     margin-top: 50px;

     @include large {
         margin-top: 100px;
     }

     .skills-grid {
         ._image {
             display: flex;
             align-items: flex-end;
             height: 100%;
             position: relative;
             z-index: 1;
             padding: 0 20px;

             @include large {
                 padding: 0;
             }

             @include small {
                 display: none;
             }
         }

         .btn-wrapper {
             margin-top: 30px;

             .btn {
                 margin-left: 15px;
                 width: 80%;

                 @include mac {
                     width: 350px;
                     margin-left: 30px;
                 }

                 @include large {
                     width: 467px;
                 }
             }
         }

         ul {
             width: 100%;
             display: flex;
             flex-flow: wrap;
             padding: 0;
             margin: 0;

             li {
                 width: calc(100% / 2 - 15px);
                 background-color: $grey;
                 border-radius: 8px;
                 display: inline-flex;
                 align-items: center;
                 justify-content: center;
                 color: $blue;
                 font-weight: 600;
                 font-size: 13px;
                 height: 45px;
                 padding: 0 10px;
                 margin: 5px 0px 5px 15px;
                 text-align: center;
                 cursor: pointer;
                 &.opacity-50 {
                    cursor: default; 
                 }

                 @include mac {
                     width: calc(100% / 2 - 30px);
                     font-size: 15px;
                     height: 60px;
                     padding: 0 30px;
                     margin: 10px 0px 10px 30px;
                 }

                 @include desktop2 {
                     font-size: 18px;
                     height: 80px;
                 }

                 @include large {
                     font-size: 24px;

                 }

                 &.active {
                     background-color: $blue;
                     color: white;
                 }

                 &:nth-child(odd) {
                     margin-right: 0;
                 }

             }
         }
     }
 }