.login-signup-page-card {
    .login-form {
        background: #FFFFFF;
        box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
        border-radius: 8px;
        padding: 30px 30px;
        text-align: center;
        width: 440px;
        min-height: 520px;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        position: relative;
        margin: 0 auto;


        @include mac {
            width: 500px;
            padding: 30px 50px;
            min-height: 520px;

        }

        @include desktop {
            width: 530px;
            padding: 30px 75px;
            min-height: 560px;

        }

        @include large {
            padding: 100px 115px;
            width: 688px;

        }
        &.verify{
            min-height: 400px;

        }

        .info {
            width: 100%;
            position: relative;
            text-align: left;
            top: -14px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #B1B7C0;
            display: flex;
            padding-left: 12px;

            @include large {
                font-size: 14px;
                padding-left: 15px;

            }

        }

        p {

            font-weight: 400;
            color: $black;

            &.size16 {
                font-size: 13px;

                @include mac {
                    font-size: 14px;

                }

                @include large {
                    font-size: 16px;

                }
            }

        }

        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            color: $black;
            margin: 0 0 8px 0;

            @include mac {
                font-size: 30px;
            }

            @include desktop {
                font-size: 34px;

            }

            @include large {
                font-size: 48px;
            }

            span {
                color: $tangerine;
            }
        }
    }
}

.left-column {
    .bg-secondary {
        position: relative;
        overflow: hidden;

        &:before {
            content: "";
            background: url('/assets/images/frame-top.svg');
            position: absolute;
            top: 0;
            right: -15px;
            z-index: 1;
            width: 238px;
            height: 308px;
            background-size: 50%;
            background-position: top right;
            background-repeat: no-repeat;

            @include mac {
                background-size: 81%;

            }
        }

        &:after {
            content: "";
            background: url('/assets/images/frame-bottom.svg');
            position: absolute;
            bottom: 0;
            left: 0px;
            z-index: 1;
            width: 424px;
            height: 364px;
            background-size: 40%;
            background-position: bottom left;
            background-repeat: no-repeat;

            @include mac {
                background-size: 65%;
            }
        }
    }

    .logo {
        position: relative;
        top: 50px;
        left: 50px;
        color: white;
        font-weight: 500;
        font-size: 20px;
        z-index: 2;
        max-width: 150px;

        @include mac {
            top: 50px;
            left: 50px;
        }

        @include desktop {
            top: 80px;
            left: 80px;

        }

        @include large {
            left: 80px;
            top: 102px;

        }
    }

    .welcome-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 30px;
        position: relative;
        z-index: 2;

        @include mac {
            padding: 0 50px;
        }

        @include desktop {
            padding: 0 70px;

        }

        @include large {
            padding: 0 80px;
        }

        .welcome-text {
            h1 {
                font-weight: 600;
                font-size: 26px;
                color: white;
                margin: 0 0 20px 0;
                padding: 0;

                @include mac {
                    font-size: 32px;
                }

                @include desktop {
                    font-size: 38px;
                    margin: 0 0 24px 0;

                }

                @include large {
                    font-size: 60px;
                }
            }

            p {

                font-weight: 400;
                color: white;
            }

            .already-account {
                padding-top: 40px;

                p {
                    margin-bottom: 24px;
                }
            }

        }
    }

}

.email-verification-message {
    margin-top: 45px;
    margin-bottom: 20px;
}