.navbar {
  padding: 0;
  margin: 0;
  .logo {
    width: 100%;
    margin-bottom: 60px;
    transition: all ease-in-out 0.3s;

    img {
      max-width: 133px;
      transition: all ease-in-out 0.3s;
      @include large {
        max-width: 180px;
      }
      &.logofull{
        display: none;
        transition: all ease-in-out 0.3s;

      }
      &.logoicon{
        display: inline-flex;
        transition: all ease-in-out 0.3s;
        max-width: 45px;
        margin-left: 7px;
        @include large {
          max-width: 55px;
          margin-left: 12px;
        }


      }
    }
  }
  .nav-content {
    background-color: $blue;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    width: 60px;
    transition: all ease-in-out 0.3s;
    display: flex;
    overflow: hidden;

    @include large {
      width: 80px;
    }

    &._expend-width {
      width: 200px;
      .logo {
        width: 100%;
        margin-bottom: 60px;
        transition: all ease-in-out 0.3s;

        img {
          max-width: 150px;
          transition: all ease-in-out 0.3s;
          &.logofull{
            display: inline-flex;
            transition: all ease-in-out 0.3s;

          }
          &.logoicon{
            display: none;
          }
        }
       
      }
      .nav {
        width: 100%;
      }

      @include large {
        width: 250px;
      }

      .profile-info {
        width: 100%;
        .arrow {
          display: inline-flex;
        }
        .profile-dropdown {
          transition: all ease-in-out 0.5s;
          width: calc(100% - 10px);

          ul {
            li {
              p {
                width: calc(100% - 45px);
              }
            }
          }
        }
      }
    }
  }

  .nav {
    display: flex;
    flex-flow: wrap;

    .nav-link {
      color: white;
      width: 100%;
      display: flex;
      height: 56px;
      align-items: center;
      padding: 0;
      margin: 0 0 10px 0;
      transition: all ease-in-out 0.3s;

      &.active {
        background-color: $tangerine;
      }

      .icon-nav {
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include large {
          width: 80px;
        }
      }

      ._nav-hover {
        @include large {
          font-size: 18px;
        }
      }
    }

    .profile-info {
      color: white;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0 0 10px 0;
      transition: all ease-in-out 0.3s;
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 210px;
      display: flex;
      flex-flow: wrap;
      align-items: flex-end;
      .arrow {
        position: absolute;
        right: 7px;
        max-width: 20px;
        transform: rotate(179deg);
        display: none;
      }

      &:after {
        // content: "";
        // position: absolute;
        // right: 10px;
        // background: url("/assets/images/white-arrow-down.png");
        // width: 20px;
        // background-size: 100%;
        // height: 20px;
        // margin-top: -7px;
        // display: none;
      }

      ._nav-hover {
        font-size: 13px;
        width: 0;
        position: relative;
      }

      &.active {
        background-color: $tangerine;
      }
      &:hover {
        // background-color: $tangerine;
        .profile-dropdown {
          opacity: 1;
        }
      }

      .profilecontent {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
      }
      .profile-dropdown {
        position: relative;
        top: 8px;
        left: 0;
        background: white;
        color: #222;
        width: calc(100% - 24px);
        margin: 0 5px;
        border-radius: 5px;
        padding: 0px;
        transition: all ease-in-out 0.3s;
        opacity: 1;
        &.admin{
          top: 52px;
        }
        @include large {
          top: 8px;
          width: calc(100% - 40px);
          &.admin{
            top: 52px;
          }
        }
        .arrow-up {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid white;
          position: absolute;
          bottom: -8px;
          left: 16px;
          transform: rotate(180deg);
          @include large {
            left: 25px;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          overflow: hidden;
          border-radius: 5px;

          li {
            width: 100%;
            display: inline-flex;
            align-items: center;
            font-size: 13px;
            margin: 0px 0;
            padding: 10px;
            cursor: pointer;
            transition: all ease-in-out 0.3s;
            position: relative;
            ._hover {
              position: absolute;
              opacity: 0;
            }

            .icon-dp {
              width: 45px;
              display: inline-flex;
              justify-content: flex-start;
              padding-left: 5px;
              @include large {
                width: 80px;
                padding-left: 12px;
              }
              img {
                max-width: 20px;
              }
            }
            p {
              font-size: 13px;
              width: 0;
              overflow: hidden;
              padding: 0;
            }
            &:hover {
              background-color: $tangerine;
              ._hover {
                position: absolute;
                opacity: 1;
              }
              ._blue {
                opacity: 0;
              }
              p {
                color: white;
                // font-weight: 500;
              }
            }
          }
        }
      }

      .icon-nav {
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include large {
          width: 80px;
        }

        ._image {
          display: flex;
          justify-content: center;
          width: 45px;
          height: 45px;
          background-color: white;
          border-radius: 50%;
          overflow: hidden;

          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 50%;
            margin: 0 auto;

            @include large {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }

  .expend-menu {
    width: 35px;
    height: 35px;
    background-color: $tangerine;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 70px;
    left: 40px;
    cursor: pointer;
    z-index: 999;
    transition: all ease-in-out 0.3s;

    @include large {
      left: 60px;
    }

    &:after {
      content: "";
      background: url("/assets/images/arrow.svg");
      width: 25px;
      height: 25px;
      background-size: 100%;
      position: absolute;
      transition: all ease-in-out 0.3s;
    }

    &.active {
      transition: all ease-in-out 0.3s;
      left: 180px;

      @include large {
        left: 230px;
      }

      &:after {
        transform: rotate(180deg);
      }
    }
  }
}
