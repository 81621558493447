//GLOBAL COLORS
$tangerine: #f7a072;
$blue: #4d60a4;
$green: #71c39c;
$error: #eb4e3d;
$yellow: #efbf62;
$suggestion: #40bbbd;
$gray: #b1b7c0;
$black: #373E45;
$white: #ffffff;
$body-bg: #FFFFFF;
$grey: #F6F8FB;


$regular: 'Poppins', sans-serif;

$base-font-family: $regular;
$base-font-size: 16px;
$base-line-height: 1.2;



