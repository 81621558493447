.my-profile-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 50px;
    position: relative;
    overflow-x: hidden;

    @include large {
        margin-top: 100px;
    }

    .left-panel {
        .member-profile {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            background-color: $grey;
            border-radius: 12px;
            padding: 20px 20px;

            @include mac {
                padding: 25px 35px;

            }

            @include large {
                padding: 25px 45px;

            }

            ._image {
                width: 64px;
                height: 64px;
                position: relative;
                border-radius: 50%;
                background-color: $white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    overflow: hidden;
                    border-radius: 50%;
                }

                &:after {
                    content: "";
                    background: url('/assets/images/Badge.svg');
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                    width: 20px;
                    height: 20px;
                }
            }

            .profile-info {
                width: calc(100% - 100px);
                padding-left: 15px;


                @include large {
                    padding-left: 24px;
                    width: calc(100% - 105px);


                }


                .name {
                    font-weight: 600;
                    font-size: 16px;
                    color: $black;
                    margin-top: 8px;

                    @include desktop {
                        font-size: 18px;

                    }

                    @include large {
                        font-size: 20px;

                    }
                }

                .designation {
                    font-weight: 400;
                    font-size: 14px;
                    color: $black;
                    margin-top: 8px;

                    @include large {
                        font-size: 16px;

                    }
                }
            }
            .btn-edit2 {
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                color: $tangerine;
                border: 0;
                padding: 0;
                
            
                @include desktop2 {
                    font-size: 16px;
            
                }
            
                @include large {
                    font-size: 18px;
            
                }
            
                &:hover {
                    color: $tangerine;
            
                }
            
            }


        }

        .structure-info {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            background-color: $grey;
            border-radius: 12px;
            margin: 24px 0 16px 0;
            padding: 20px 20px;

            @include mac {
                padding: 25px 35px;

            }

            @include large {
                padding: 25px 45px;

            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    width: 100%;
                    display: inline-flex;
                    font-weight: 500;
                    font-size: 14px;
                    color: $black;
                    padding-bottom: 15px;

                    @include mac {
                        font-size: 15px;

                    }

                    @include large {
                        font-size: 16px;

                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    .info-heading {
                        width: 80%;
                    }

                    .percentage-info {
                        width: 20%;
                        display: inline-flex;
                        justify-content: flex-end;
                    }
                }
            }
        }

        .skill-improve-info {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            background-color: $grey;
            border-radius: 12px;
            margin: 24px 0 16px 0;
            padding: 20px 20px;
            flex-direction: column;

            @include mac {
                padding: 35px 35px;

            }

            @include large {
                padding: 45px 45px;

            }

            h3 {
                font-weight: 600;
                font-size: 18px;
                color: $black;
                margin-bottom: 24px;

                @include mac {
                    font-size: 21px;

                }

                @include large {
                    font-size: 24px;

                }
            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    width: 100%;
                    display: inline-flex;
                    font-weight: 500;
                    font-size: 14px;
                    color: $black;
                    padding-bottom: 15px;

                    @include mac {
                        font-size: 15px;

                    }

                    @include large {
                        font-size: 16px;

                    }
                }
            }
        }

    }


}

.dimension-panel {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding: 40px 10px !important;
    width: 80% !important;
    margin-left: 10%;
    margin-right: 10%;
    height: 100% !important;

    // @include mac {
    //     padding: 100px 50px;

    // }

    .grid {
        width: calc(100% / 3);

        .dimension-text {
            border-radius: 15px;
            border: solid 1px #ccc;
            padding: 7px 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            color: $black;
            justify-content: center;
            width: 90%;
            margin: 0 auto;


            @include mac {
                font-size: 14px;
                padding: 10px 10px;

            }

            @include desktop2 {
                font-size: 16px;

            }

            @include large {
                font-size: 20px;

            }
        }

        &.left-grid {
            padding: 0 10px;

            .dimension-text {
                &._top {
                    margin-top: 105px;

                    @include mac {
                        margin-top: 120px;
                    }

                    @include desktop {
                        margin-top: 125px;
                    }

                    @include desktop2 {
                        margin-top: 135px;
                    }

                    @include large {
                        margin-top: 145px;

                    }
                }

                &._bottom {
                    margin-top: 65px;

                    @include mac {
                        margin-top: 75px;

                    }

                    @include desktop {
                        margin-top: 80px;

                    }

                    @include desktop2 {
                        margin-top: 105px;

                    }

                    @include large {
                        margin-top: 125px;
                    }
                }
            }
        }

        &.center-grid {
            .dimension-text {
                margin: 20px auto;
            }
        }
    }

}

.logout .header{

    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #f7a072;
    border: 0;
}

