
.modal-backdrop.show {
  width: 100%;
  height: 100%;
}
.container-fluid{
  @include mobile {
    overflow: hidden;
  }
}
.modal.show .modal-dialog {
  transform: none;
  height: 100vh;
  display: flex;
  align-items: center;
  border-radius: 12px;
  z-index: 999;

  margin-top: 0;
  margin-bottom: 0;
  @include small {

  }

  @include large {
    width: 788px;
    max-width: 788px;
    border-radius: 16px;
  }


  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 3.7rem !important;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 11px;
    @include large {
    width: 4.5rem !important;

    }
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    font-weight: bold;
    font-size: 11px;
  }
  .modal-content {
    padding: 30px 30px;
    border-radius: 12px;
    max-height: 90%;

    @include large {
      padding: 64px 50px;
      border-radius: 16px;
    }

    form {
      .form-field {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .modal-header {
      border-bottom: none;
      margin: 0;
      padding: 0;

      .btn-close {
        width: 14px;
        height: 14px;
        opacity: 0.8;
        background-size: 100%;
        position: relative;
        right: 0px;

        @include large {
          right: -10px;
          width: 20px;
          height: 20px;
        }
      }

      .modal-title.h4 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        width: 100%;

        @include large {
          font-size: 48px;
        }
      }

      .sub-title {
        text-align: center;
      }
    }

    p {
      text-align: center;
      margin-top: 10px;
    }

    .modal-body::-webkit-scrollbar {
      background: rgba(77, 116, 164, 0.1);
      width: 7px;
      border-radius: 12px;
    }

    .modal-body::-webkit-scrollbar-track {
      background: rgba(77, 116, 164, 0.1);
    }

    .modal-body::-webkit-scrollbar-thumb {
      background-color: rgba(77, 96, 164, 0.5);
      border-radius: 12px;
      border: 0px solid #f4f4f5;
      opacity: 0.1;
    }

    .modal-body::-webkit-scrollbar-button {
      display: none;
    }

    .modal-body {
      max-height: calc(375px);
      overflow-y: auto;
      margin-bottom: 10px;
      margin-top: 0px;
      margin-bottom: 15px;
      padding-top: 0;
      .rs-picker-default .rs-picker-toggle {
        cursor: pointer;
        background: white;
        border: 2px solid #cdd5df;
        border-radius: 8px;
        height: 50px;
        padding: 0px 0 0 10px;
        margin: 0;
        font-size: 14px;
        color: #373e45;
        .rs-stack {
          height: 45px;
        }
        .rs-picker-toggle-caret {
          height: 26px;
          font-size: 16px;
        }
      }

      @include large {
        margin-top: 30px;
        margin-bottom: 25px;
      }

      form {
        width: 100%;
        min-height: 200px;
        margin: 0;
      }

      .upload-photo {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 15px;
        position: relative;

        @include large {
          margin-top: 0;
        }
        &.justify-center {
          justify-content: center;
        }
        .max-img-size-error {
          color: red;
          font-size: 12px;
          position: absolute;
          bottom: 0;
          left: 110px;
          width: 300px;
        }

        .cover-photo {
          width: 85px;
          height: 85px;
          border-radius: 50%;
          border: solid 2px $blue;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          @include large {
            width: 106px;
            height: 106px;
          }

          &:after {
            content: "";
            background: url("/assets/images/photoCamera.svg");
            width: 30px;
            height: 30px;
            position: absolute;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            background-color: white;
          }

          .coverimg {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all ease-in-out 0.3s;

            &:hover {
              .hover-action {
                opacity: 1;
              }
            }

            .hover-action {
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.45);
              z-index: 4;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: all ease-in-out 0.3s;

              img {
                width: 32px;
                height: 32px;
                cursor: pointer;
                background: transparent;
              }
            }
          }
        }
      }
    }

    .modal-footer {
      border-top: none;

      .btn.btn-cancel {
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        height: 27px;
        width: 100%;
        text-transform: uppercase;
        color: #4d60a4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        @include large {
          margin-top: 25px;
        }
      }
    }
  }

  &.team-memeber-model {
    width: 550px;
    max-width: 550px;

    @include large {
      width: 788px;
      max-width: 788px;
      border-radius: 16px;
    }

    .modal-content {
      padding: 30px 60px;
      border-radius: 12px;

      @include large {
        padding: 64px 100px;
        border-radius: 16px;
      }
    }
  }

  &.model-common {
    width: 500px;
    max-width: 500px;
    @include mobile {
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
      text-align: center;
    }

    @include large {
      width: 700px;
      max-width: 700px;
      border-radius: 16px;
    }

    .modal-header {
      flex-flow: wrap;
      justify-content: center;

      .btn-close {
        width: 10px;
        height: 10px;
        top: 30px;
        right: 30px;
        position: absolute;

        @include large {
          width: 14px;
          height: 14px;
          top: 40px;
          right: 40px;
        }
      }

      ._icon-img {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-flow: wrap;
        margin: 0px 0 0px 0;

        @include large {
          font-size: 32px;
          margin: 0px 0 0px 0;
        }

        img {
          max-width: 70px;

          @include large {
            max-width: 100px;
          }
        }
      }

      .modal-title.h4 {
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        width: 100%;
        margin: 10px 0 0 0;

        @include large {
          font-size: 32px;
          margin: 10px 0 0 0;
        }
      }
    }
    .donetag-dialog {
      width: 68px;
      height: 42px;
      background: #71c49c;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      line-height: 27px;
      color: white;
      position: absolute;
      left: 15px;
      top: 15px;
    }
    .modal-body {
      padding-top: 13px;
      &.ovr-visible {
        overflow: visible;
      }

      .rs-slider-progress-bar {
        background-color: transparent !important;
      }
      .rs-slider-handle::before {
        background-color: $blue !important;
        border: 0 !important;

        width: 15px;
        height: 15px;
      }
      .rs-slider-handle:hover::before,
      .rs-slider-handle:focus::before {
        box-shadow: none;
      }
      .rs-slider:hover .rs-slider-bar {
        background-color: #ccd2db !important;
        height: 3px;
      }
      .rs-slider-bar {
        background-color: #ccd2db !important;
        height: 3px;
        position: relative;
        top: 4px;
      }
      .rs-slider-graduator > ol > li:last-child::after,
      .rs-slider-graduator > ol > li::before {
        display: none;
      }
      .rs-slider-graduator > ol > li:nth-of-type(1)::before,
      .rs-slider-graduator > ol > li:nth-of-type(6)::before,
      .rs-slider-graduator > ol > li:nth-of-type(10)::before {
        display: block;
        background-color: #b1b7c0;
        border: 0;
        top: -2px;
      }
      .rs-slider-graduator > ol > li:nth-of-type(10)::before {
        right: -1px;
      }
      .rs-slider-graduator > ol > li.rs-slider-active::before {
        visibility: visible;
      }
      .rs-slider-graduator > ol > li:nth-of-type(1)::after {
        background: url("/assets/images/smilee-red.svg");
        position: absolute;
        top: -60px;
        left: -22px;
        content: "";
        width: 46px;
        height: 45px;
        background-size: 100%;
      }
      .rs-slider-graduator > ol > li:nth-of-type(6)::after {
        background: url("/assets/images/smilee-yellow.svg");
        position: absolute;
        top: -60px;
        left: -22px;
        content: "";
        width: 46px;
        height: 45px;
        background-size: 100%;
      }
      .rs-slider-graduator > ol > li:nth-of-type(9)::after {
        background: url("/assets/images/smilee-green.svg");
        position: absolute;
        top: -60px;
        right: -55px;
        content: "";
        width: 46px;
        height: 45px;
        background-size: 100%;
      }
      .rs-slider {
        position: relative;
        margin: 35px 20px 55px 20px;
      }
    }

    .modal-content {
      padding: 30px 30px;
      border-radius: 12px;

      @include large {
        padding: 50px 50px;
        border-radius: 16px;
      }
    }

    ul {
      width: 340px;
      text-align: left;
      padding-top: 10px;
      margin: 0 auto;

      li {
        margin: 13px 0;

        &.active {
          .sizes {
            background-color: $blue;
            color: white;
          }
        }

        .sizes {
          position: relative;
          display: inline-block;
          margin-right: 15px;
          width: 44px;
          height: 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $black;
          border: 1px solid $blue;
          border-radius: 6px;
        }

        &:nth-child(2) {
          span {
            height: 50px;
          }
        }

        &:nth-child(3) {
          span {
            height: 50px;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      flex-flow: wrap;
      width: 100%;
      justify-content: center;
      margin-top: 10px;
      padding-bottom: 0;

      .btn {
        width: calc(100% / 2 - 13px);
        margin-right: 20px;
        font-weight: 600;
        font-size: 16px;
        height: 48px;
        margin-top: 0;

        @include large {
          font-size: 18px;
          height: 60px;
        }

        &.btn-link {
          border: solid $blue 1px;
          border-radius: 8px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.full {
          width: 100%;
          margin: 5px 0;
          font-size: 18px;
        }

        &.btn-cancel {
          margin-top: 10px;
          font-weight: 600;
          font-size: 18px;
        }

        &.btn-download2 {
          background-color: transparent;
          border: solid 1px $blue;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          .icon-download {
            margin-right: 7px;
          }
        }
      }
    }
  }
}
