.faq-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin-top: 35px;

    .faq-content {
        padding-right: 30px;
        padding-top: 70px;

        @include large {
            padding-right: 60px;
            padding-top: 100px;
        }

        @include small {
            padding-right: 0px;
            padding-top: 0px;

        }

        .accordion {
            .accordion-header {
                box-shadow: none;
                background-color: transparent;
            }

            .accordion-item {
                background: transparent;
                box-shadow: none;
                border: 0;
                border-bottom: solid 1px #ccc;
                padding-bottom: 15px;
                margin-bottom: 30px;

            }

            .accordion-item:first-of-type .accordion-button {
                box-shadow: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 21px;
                color: $black;

                @include large {
                    font-size: 24px;

                }

                &:hover {
                    color: $blue;
                }

            }

            .accordion-button::after {
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                margin-left: auto;
                background-repeat: no-repeat;
                background-size: 100%;
                margin-right: 15px;

                @include large {
                    width: 26px;
                    height: 26px;

                }

            }

            .heading {
                width: 100%;
                padding-right: 15px;
            }

            p {
                padding: 0;
            }

            .accordion-button:not(.collapsed) {
                box-shadow: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 21px;
                color: $blue;

                @include large {
                    font-size: 24px;

                }
            }

            .accordion-button {
                box-shadow: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 21px;
                color: $black;

                @include large {
                    font-size: 24px;

                }

                &:hover {
                    color: $blue;
                }

            }

        }
    }

    .faq-content-info {
        padding-left: 25px;

        @include small {
            padding-left: 0px;
            padding-top: 30px;

        }

        .contact-detail {
            background: #FFF9F5;
            border-radius: 16px;
            padding: 40px 30px;

            @include large {
                padding: 50px 48px;

            }
        }

        h3 {
            font-weight: 700;
            font-size: 24px;
            color: $black;
            margin-bottom: 24px;

            @include large {
                font-size: 32px;

            }
        }

        ._image {
            margin-top: 50px;
            margin-bottom: 35px;
        }
    }


}